import {
  ScorecardEvaluationQuestion,
  ShortScorcardType,
} from "../pages/call-details/RecordingScorecardTab";
import {
  Comment,
  InsertRecordingComment,
  Recording,
  RecordingMoment,
  RecordingSegmentSentiment,
  RecordingTopic,
  RecordingTranscription,
  UpdateRecordingComment,
} from "../types/recording";
import { RecordingLockedSegment } from "../types/recording/RecordingLockedSegment";
import { RecordingScorecard } from "../types/recording/RecordingScorecard";
import { Topic } from "../types/topic";
import { axiosForShare, axiosWithAuth } from "../utils/customAxios";

const recordingsService = {
  fetchRecording: async (
    id: number,
    shared: boolean = false
  ): Promise<Recording> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/recording/${id}`);
      const recording: Recording = response.data;

      return recording;
    } catch (err) {
      throw err;
    }
  },

  fetchRecordingTranscription: async (
    id: number,
    shared: boolean = false
  ): Promise<RecordingTranscription> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/recording/${id}/transcript`);
      const transcription: RecordingTranscription = response.data;

      return transcription;
    } catch (err) {
      throw err;
    }
  },

  fetchLockedSegments: async(id: number, shared: boolean = false) : Promise<RecordingLockedSegment[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/recording/${id}/lockedSegments`);
      const segments: RecordingLockedSegment[] = response.data;

      return segments;
    } catch (err) {
      throw err;
    }
  },

  lockRecordingSegment: async(id: number, lockedSegments : RecordingLockedSegment[], shared: boolean = false) => {
    try{  
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.put(`/recording/${id}/lock`, lockedSegments);
      const message = response.data;
      return message;
    }catch(err){
      throw err;
    }
  },

  unlockRecordingSegment: async(id: number, lockedSegments : RecordingLockedSegment[], shared: boolean = false) => {
    try{  
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.put(`/recording/${id}/unlock`, lockedSegments);
      const message = response.data;
      return message;
    }catch(err){
      throw err;
    }
  },
  fetchRecordingComments: async (
    recordingId: number,
    shared: boolean = false
  ): Promise<Comment[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/recording/${recordingId}/comment`
      );
      const comments: Comment[] = response.data;
      return comments;
    } catch (err) {
      throw err;
    }
  },

  addRecordingComment: async (
    recordingId: number,
    newComment: InsertRecordingComment,
    shared: boolean = false
  ): Promise<Comment> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.post(
        `/recording/${recordingId}/comment`,
        newComment
      );
      const createdComment: Comment = response.data;
      return createdComment;
    } catch (err) {
      throw err;
    }
  },

  updateRecordingComment: async (
    recordingId: number,
    commentId: number,
    editedComment: UpdateRecordingComment,
    shared: boolean = false
  ): Promise<Comment> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.put(
        `/recording/${recordingId}/comment`,
        editedComment
      );
      const updatedComment: Comment = response.data;
      return updatedComment;
    } catch (err) {
      throw err;
    }
  },

  deleteRecordingComment: async (
    recordingId: number,
    commentId: number,
    shared: boolean = false
  ): Promise<void> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.delete(
        `/recording/${recordingId}/comment/${commentId}`
      );
      if (response.data <= 0) {
        throw Error("Recording comment could not be deleted!");
      }
    } catch (err) {
      throw err;
    }
  },

  retainRecording: async (recordingId: number, isMarkedForRetention: boolean, shared: boolean): Promise<boolean> =>{
    try{
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.put(`/recording/${recordingId}/retention`
        +`?isMarkedForRetention=${isMarkedForRetention}`);
      const isRetained: boolean = response.data;

      return isRetained;
    }catch(err){
      throw err;
    }
  },

  deleteRecording: async (recordingId: number, shared: boolean): Promise<boolean> =>{
    try{
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.put(`/recording/${recordingId}/deletion`);
      const isDeleted: boolean = response.data;

      return isDeleted;
    }catch(err){
      throw err;
    }
  },

  fetchRecordingScorecard: async (
    recordingId: number,
    shared: boolean = false
  ): Promise<RecordingScorecard> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/recording/${recordingId}/scorecard`
      );
      const scorecard: RecordingScorecard = response.data;

      return scorecard;
    } catch (err) {
      throw err;
    }
  },

  addRecordingScorecard: async (
    recordingId: number,
    scorecard: ShortScorcardType,
    evaluation: ScorecardEvaluationQuestion[],
    shared: boolean = false
  ) => {
    try {
      let score = 0;
      evaluation.forEach((e) => (score += e.rating));
      score = score / evaluation.length;
      const recordingScorecard: RecordingScorecard = {
        scorecardName: { id: scorecard.id, name: scorecard.text },
        score: score,
        scoreDetails: evaluation.map((e) => {
          return {
            scorecardGroupName: e.category,
            question: { id: e.id, questionText: e.question, weightage: 0 },
            relevant: 0,
            questionScore: e.rating,
          };
        }),
      };

      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.post(
        `/recording/${recordingId}/scorecard`,
        recordingScorecard
      );
      const createdRecordingScorecard: RecordingScorecard = response.data;
      return createdRecordingScorecard;
    } catch (err) {
      throw err;
    }
  },

  fetchRecordingMoments: async (
    recordingId: number,
    shared: boolean = false
  ): Promise<RecordingMoment[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/recording/${recordingId}/moment`
      );
      const moments: RecordingMoment[] = response.data;

      return moments;
    } catch (err) {
      throw err;
    }
  },

  fetchRecordingTopics: async (
    recordingId: number,
    shared: boolean = false
  ): Promise<RecordingTopic[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/recording/${recordingId}/topic`
      );
      const topics: RecordingTopic[] = response.data;

      return topics;
    } catch (err) {
      throw err;
    }
  },

  fetchRecordingSegmentSentiments: async (
    recordingId: number,
    shared: boolean = false
  ): Promise<RecordingSegmentSentiment[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/recording/${recordingId}/segmentsentiment`
      );
      const segmentSentiments: RecordingSegmentSentiment[] = response.data;

      return segmentSentiments;
    } catch (err) {
      throw err;
    }
  },
};

export default recordingsService;
