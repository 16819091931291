import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { TinyUser } from "../../types/user";

type ParticipantPopupProps = {
    toggleDialog? : () => void;
    participants : TinyUser[];
  };

const ParticipantPopup: React.FC<ParticipantPopupProps> = ({
    toggleDialog,
    participants
}) => {
 
    const [searchTerm,setSearchTerm] = useState<string>("");
    const [participantsFiltered, setParticipantsFiltered] = useState<TinyUser[]>();

    useEffect(()=>{
       setParticipantsFiltered(participants.filter((s) => s.email?.includes(searchTerm) 
       || s.firstName?.includes(searchTerm) || s.lastName?.includes(searchTerm)));
    },[searchTerm]);

    return (
    <Dialog
        className="dialogShareList"
        title={"Participant"}
        onClose={toggleDialog}
      >
        <div
          className="shareList"
          style={{ minWidth: "320px", maxWidth: "320px" }}
        >
          <div className="detailColBox">
            <div className="UserLiSearch p-b-5">
              <div className="formInput">
                <Input
                  style={{ height: "32px" }}
                  placeholder="Search..."
                  value={searchTerm}                  
                  onChange={(e) => setSearchTerm(e.value)}
                />
              </div>
            </div>
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.1)",
                padding: "10px 0 10px 10px",
                float: "left",
                width: "100%",
                borderRadius: "2px",
              }}
            >
              <div
                className="uLiBox"
                style={{
                  maxHeight: "300px",
                  minHeight: "300px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "0 10px 0 0",
                }}
              >                
                {participantsFiltered && participantsFiltered.map((s) =>{
                      return(<div className="uLiBoxRow">
                      <div className="tblUsr">
                        <div className="topUsrAreaPic">
                          <div className="topUsrAreaPic-i">
                            <div className="contact-list-icon bg-darksteelblue">
                              <div className="contact-list-icon-txt">
                                {(s.firstName || s.lastName) ?
                                (s.firstName == null ?"": s.firstName.charAt(0))
                                + (s.lastName == null?"": s.lastName?.charAt(0)) :
                                (<span className="fs-16">
                                  <i className="bi bi-telephone"></i>
                                </span>)
                                }
                              </div>
                            </div>
                          </div>
                          <div className="topUsrAreaPic-tx hoverTextFull">
                           {(s.firstName || s.lastName) &&
                            (<span
                              className="text-primary font-weight-semi cursor-default
                                text-decoration-none float-left w-100 line-height-2 
                                mx-td-spn viewFullWordHover"
                              title={(s.firstName == null?"":s.firstName) + " " + (s.lastName == null?"":s.lastName)}
                            >
                              {(s.firstName == null?"":s.firstName) + " " + (s.lastName == null?"":s.lastName)}
                            </span>)}
                            <span
                              className="text-muted fs-13 line-height-2 
                               mx-td-spn viewFullWordHover"
                              title={s.email}
                            >
                              {s.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>)
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>);
};

export default ParticipantPopup;