import { GridCellProps } from "@progress/kendo-react-grid";
import useSwal from "../../../../hooks/useSwal";
import { useEffect, useState } from "react";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import { SweetAlertOptions } from "sweetalert2";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import useMasterData from "../../../../hooks/useMasterData";
import { ParticipantNumber } from "./RecordingPolicySettings";
import { Loader } from "@progress/kendo-react-indicators";
import React from "react";

interface RecordingPolicyForMobileProps extends GridCellProps {
    dataItem: { id: number, participantNumber: string };
    onEditHandler: (data: ParticipantNumber) => void
    onDeleteHandler: (data: ParticipantNumber) => void
    loading: boolean;
    error: string | undefined
}

const RecordingPolicySettingsForMobile = (props: RecordingPolicyForMobileProps) => {
    const masterData = useMasterData();
    const swal = useSwal();
    const localeCtx = useLocale();

    const [translationsLoading, setTranslationsLoading] = useState<boolean>(false);
    const [translations, setTranslations] = useState<Dictionary<string> | undefined>
        (localeCtx?.selectedLocale?.current.componentTranslations["RecordingPolicySettings"]);

    useEffect(() => {
        if (!localeCtx?.selectedLocale?.current.componentTranslations["RecordingPolicySettings"]) {
            fetchTranslations();
        }
    }, [localeCtx?.selectedLocale]);

    const fetchTranslations = async () => {
        try {
            setTranslationsLoading(true);
            const resp = await localeCtx?.setComponentTranslations("RecordingPolicySettings");
            setTranslations(resp);
        } catch (err) {
            console.error(err);
            setTranslations(
                localeCtx?.selectedLocale?.previous.componentTranslations["RecordingPolicySettings"]
            );
            localeCtx?.setPreviousAppLocale("RecordingPolicySettings");
            if (localeCtx?.localeSwitchFailed) {
                const swalOptions: SweetAlertOptions<any, any> = {
                    icon: "error",
                    title: "Error",
                    text: "Couldn't Switch Language",
                };
                await swal.fire(swalOptions);
            }
        } finally {
            setTimeout(() => {
                setTranslationsLoading(false);
            }, 100);
        }
    };

    const fetchLabelKeyTranslation = (
        key: string,
        defaultValue: string
    ): string => {
        return translations && translations[key] ? translations[key] : defaultValue;
    };

    const participantNumber = props.dataItem.participantNumber ?? "";
    const loading = props.loading;
    const error = props.error;

    return (
        <td className="listMobile">
            <div className="listRowMobile float-left p-b-1">
                {loading && <Loader type={"infinite-spinner"} />}
                {!loading && error && (
                    <span className="fs-15">
                        <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                        {`${translationsLoading
                            ? "Uh Oh! Something Went Wrong. Please Try Again!"
                            : fetchLabelKeyTranslation(
                                "ErrMsg",
                                "Uh Oh! Something Went Wrong. Please Try Again!"
                            )
                            }`}
                    </span>
                )}

                {!loading && !error
                    &&
                    <React.Fragment>
                        <div className="float-left w-100">
                            <div className="text-primary font-weight-semi line-height-2">
                                <span>
                                    {participantNumber}
                                </span>
                            </div>
                        </div>
                        <div className="iconMobileDetail" style={{ paddingRight: "20px" }}>
                            <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
                                <span className="iBtn d-flex justify-content-end">
                                    <Button
                                        className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn 
                                ${masterData?.telepoDisableConfig?.systemSetting.recordingPolicy.disableChanges
                                                ? "disabledBtn"
                                                : ""
                                            }`}
                                        onClick={() => props.onEditHandler(props.dataItem)}
                                        disabled={
                                            masterData?.telepoDisableConfig?.systemSetting.recordingPolicy
                                                .disableChanges
                                        }
                                    >
                                        <span
                                            className="fs-17 line-height-1 text-primary"
                                            title={`${translationsLoading
                                                ? "Edit"
                                                : fetchLabelKeyTranslation("EditButton", "Edit")
                                                }`}
                                        >
                                            <i className="bi bi-pencil"></i>
                                        </span>
                                    </Button>
                                    <Button
                                        className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn 
                                ${masterData?.telepoDisableConfig?.systemSetting.recordingPolicy.disableChanges
                                                ? "disabledBtn"
                                                : ""
                                            }`}
                                        onClick={() => props.onDeleteHandler(props.dataItem)}
                                        disabled={
                                            masterData?.telepoDisableConfig?.systemSetting.recordingPolicy
                                                .disableChanges
                                        }
                                    >
                                        <span
                                            className="fs-17 line-height-1 text-primary"
                                            title={`${translationsLoading
                                                ? "Delete"
                                                : fetchLabelKeyTranslation("DeleteButton", "Delete")
                                                }`}
                                        >
                                            <i className="bi bi-trash3"></i>
                                        </span>
                                    </Button>

                                </span>
                            </Tooltip>
                        </div>
                    </React.Fragment>
                }
            </div>
        </td>
    );
};

export default RecordingPolicySettingsForMobile;