import { GridCellProps } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { CallDetail } from "../../../types/call";
import { ColorCategory } from "../../../types/master-data";
import { CallDetailTabEnum } from "../../../enums/callDetailTabEnum";
import CallFlag from "../CallFlag";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
  handleFlagSelection: (
    selectedFlag: ColorCategory,
    callDetailData: CallDetail
  ) => void;
  handleCallDetailNavigation: (
    callDetail: CallDetail,
    tab: CallDetailTabEnum
  ) => void;
}

const CustomGridCellForCallGroupedIcons = (props: CustomGridCellProps) => {
  const scoreColor =
    props.dataItem.recordings[0].recordingScorecardScore !== undefined
      ? props.dataItem.recordings[0].recordingScorecardScore >= 3
        ? "tx-green"
        : props.dataItem.recordings[0].recordingScorecardScore >= 1.5
        ? "tx-amber"
        : "tx-red"
      : "";
  const sentiment: any = props.dataItem.recordings[0].sentiment;
  const isAIAnalysed = props.dataItem.recordings[0].isAIAnalysed;
  let nameWithHighSentiment = undefined;
  if (
    sentiment &&
    (sentiment.positivePrediction ||
      sentiment.negativePrediction ||
      sentiment.neutralPrediction)
  ) {
    nameWithHighSentiment = Object.keys(sentiment).reduce((a, b) =>
      sentiment[a] > sentiment[b] ? a : b
    );
  }

  const handleFlagSelection = (selectedFlag: ColorCategory) => {
    props.handleFlagSelection(selectedFlag, props.dataItem);
  };

  return (
    <td className="text-left">
      <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
        <span className="iBtn d-flex">
          <CallFlag
            colorCategory={props.dataItem.colorCategory}
            handleFlagSelection={handleFlagSelection}
          />
          {props.dataItem.isEvaluated ? (
            <Button
              className="radius-50 d-flex align-items-center justify-conter m-r-3"
              onClick={() =>
                props.handleCallDetailNavigation(
                  props.dataItem,
                  CallDetailTabEnum.Evaluation
                )
              }
            >
              <span
                className={`fs-17 line-height-1 ${scoreColor}`}
                title="Evaluated"
              >
                <i className="bi bi-check-circle"></i>
              </span>
            </Button>
          ) : (
            <Button
              className="radius-50 d-flex align-items-center justify-conter m-r-3"
              onClick={() =>
                props.handleCallDetailNavigation(
                  props.dataItem,
                  CallDetailTabEnum.Evaluation
                )
              }
            >
              <span
                className="fs-17 line-height-1 text-black-6"
                title="Not Evaluated"
              >
                <i className="bi bi-check-circle"></i>
              </span>
            </Button>
          )}
          {nameWithHighSentiment && isAIAnalysed ? (
            <Button
              className="radius-50 d-flex align-items-center justify-conter m-r-3"
              onClick={() =>
                props.handleCallDetailNavigation(
                  props.dataItem,
                  CallDetailTabEnum.Transcript
                )
              }
            >
              {nameWithHighSentiment === "positivePrediction" && (
                <span
                  className="fs-17 line-height-1 text-success"
                  title="Positive"
                >
                  <i className="bi bi-emoji-smile"></i>
                </span>
              )}
              {nameWithHighSentiment === "negativePrediction" && (
                <span className="fs-17 line-height-1 tx-red" title="Negative">
                  <i className="bi bi-emoji-frown"></i>
                </span>
              )}
              {nameWithHighSentiment === "neutralPrediction" && (
                <span className="fs-17 line-height-1 tx-amber" title="Neutral">
                  <i className="bi bi-emoji-neutral"></i>
                </span>
              )}
            </Button>
          ) : (
            <Button
              className="radius-50 d-flex align-items-center justify-conter m-r-3"
              onClick={() =>
                props.handleCallDetailNavigation(
                  props.dataItem,
                  CallDetailTabEnum.Transcript
                )
              }
              title={isAIAnalysed ? "No Sentiment Found" : undefined}
            >
              <span className="fs-17 line-height-1 text-black-6">
                <i className="bi bi-emoji-smile"></i>
              </span>
            </Button>
          )}
        </span>
      </Tooltip>
    </td>
  );
};

export default CustomGridCellForCallGroupedIcons;
