import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { MasterDataProvider } from "../contexts/MasterDataProvider";

const ProtectedRoutes: React.FC = () => {
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    console.log("Here in Protected Routes Page");
  }, []);

  return auth?.accessToken ? (
    <div className="float-left w-100 p-t-55">
      <div className="mobile-contain h-100 pt-4 p-l-15 p-r-15">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12">
              <MasterDataProvider>
                <Outlet />
              </MasterDataProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
