import { CustomerCipherDetail, CustomerCLIMasking, PlatformDetail, UpsertCustomer } from "../types/customer";
import { CRMSystemDetail, UpsertCRMSystemDetail } from "../types/customer/CRMSystemDetail";
import { Customer } from "../types/customer/Customer";
import { SMTPServerDetail } from "../types/customer/SMTPServerDetail";
import { axiosWithAuth } from "../utils/customAxios";
import { CustomerMfaModes } from "../types/customer/CustomerMfaModes";
import { UpsertCustomerMfaConfig } from "../types/customer/UpsertCustomerMfaConfig";
import { CustomerPlatformStatus } from "../types/customer/CustomerPlatformStatus";
import { UpdateMsTeamsRecordingPolicyStatus } from "../types/customer/UpdateMsTeamsRecordingPolicyStatus";

const customerService = {
  getCustomerDetailsById: async (id: number): Promise<Customer> => {
    try {
      const response = await axiosWithAuth.get(`/customer/${id}`);
      const customer: Customer = response.data;
      return customer;
    } catch (ex) {
      throw ex;
    }
  },
  updateCustomerDetailsById: async (
    id: number,
    customer: UpsertCustomer
  ): Promise<Customer> => {
    const response = await axiosWithAuth.put(`/customer/${id}`, customer);
    const updatedCustomer: Customer = response.data;
    return updatedCustomer;
  },
  getCustomerCLIMasking: async (id: number): Promise<CustomerCLIMasking> => {
    const response = await axiosWithAuth.get(`/customer/${id}/cli`);
    const masking: CustomerCLIMasking = response.data;

    return masking;
  },
  upsertCustomerCLIMasking: async (
    customerId: number,
    masking: CustomerCLIMasking
  ): Promise<Customer> => {
    const response = await axiosWithAuth.post(
      `/customer/${customerId}/cli`,
      masking
    );
    const customer: Customer = response.data;
    return customer;
  },
  deleteCustomerCLIMasking: async (
    customerId: number
  ) => {
    const response = await axiosWithAuth.delete(`/customer/${customerId}/cli`)
    return response.data
  },
  getCustomerCipherDetail: async (
    id: number
  ): Promise<CustomerCipherDetail> => {
    const response = await axiosWithAuth.get(`/customer/${id}/cipher`);
    const cipherDetail: CustomerCipherDetail = response.data;

    return cipherDetail;
  },
  upsertCustomerCipherDetail: async (
    customerId: number
  ): Promise<Customer> => {
    const response = await axiosWithAuth.post(`/customer/${customerId}/cipher`);
    const updatedCustomer: Customer = response.data;

    return updatedCustomer;
  },
  getCustomerSMTPServerDetails: async (
    customerId: number
  ): Promise<SMTPServerDetail> => {
    const response = await axiosWithAuth.get(`/customer/${customerId}/smtp`);
    const serverDetails: SMTPServerDetail = response.data;

    return serverDetails;
  },
  upsertCustomerSMTPServerDetails: async (
    customerId: number,
    smtpServerDetails: SMTPServerDetail
  ): Promise<SMTPServerDetail> => {
    const response = await axiosWithAuth.post(
      `/customer/${customerId}/smtp`,
      smtpServerDetails
    );
    const updatedSMTP : SMTPServerDetail = response.data;
    return updatedSMTP;
  },
  deleteCustomerSMTPServerDetails: async (
    customerId: number,
    smtpServerId: number
  ): Promise<void> => {
    await axiosWithAuth.delete(`/customer/${customerId}/smtp/${smtpServerId}`);
  },
  getCustomerPlatformDetail: async (
    id: number
  ): Promise<PlatformDetail[]> => {
    const response = await axiosWithAuth.get(`/customer/${id}/platformdetail`);
    const customerPlatformDetail: PlatformDetail[] = response.data;
    return customerPlatformDetail;
  },
  GetCustomerPlatformStatus: async (
    id: number, platform: string
  ): Promise<CustomerPlatformStatus> => {
    const response = await axiosWithAuth.get(`/customer/${id}/PlatformStatus/${platform}`);
    const customerPlatformStatus: CustomerPlatformStatus = response.data;
    return customerPlatformStatus;
  },
  UpdateMsTeamsPolicyStatus: async (
    id: number, recordingPolicyStatus: UpdateMsTeamsRecordingPolicyStatus
  ): Promise<boolean> => {
    const response = await axiosWithAuth.post(`/customer/${id}/UpdateMsTeamsPolicyStatus`, recordingPolicyStatus);
    const result: boolean = response.data;
    return result;
  },
  ResendMsAdminConsentEmail: async (
    id: number, platform: string
  ): Promise<boolean> => {
    const response = await axiosWithAuth.put(`/customer/${id}/ResendMsAdminConsentEmail/${platform}`);
    const result: boolean = response.data;
    return result;
  },
  getCustomerCRMSystemDetails: async (customerId: number): Promise<CRMSystemDetail> => {
    const response = await axiosWithAuth.get(`/customer/${customerId}/crm`);
    const crmDetails: CRMSystemDetail = response.data;
    return crmDetails;    
  },
  upsertCustomerCRMSystemDetails: async (customerId: number, crmSystemDetails: UpsertCRMSystemDetail): Promise<CRMSystemDetail> => {
    const response = await axiosWithAuth.post(`/customer/${customerId}/crm`, crmSystemDetails);
    const updatedCrm: CRMSystemDetail = response.data;
    return updatedCrm;
  },  
  deleteCustomerCRMSystemDetails: async (customerId: number): Promise<void> => {
    await axiosWithAuth.delete(`/customer/${customerId}/crm`);
  },
  getCustomerMFAModes: async (customerId: number): Promise<CustomerMfaModes> =>{
    const response = await axiosWithAuth.get(`/customer/${customerId}/mfa`)
    return response.data;
  },
  upsertMFAModes : async(customerId : number, upsertCustomerMfaConfig : UpsertCustomerMfaConfig) :Promise<void> =>{
    const response = await axiosWithAuth.patch(
      `/customer/${customerId}/mfa`,
      upsertCustomerMfaConfig
    );
  }
};

export default customerService;
