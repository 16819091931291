import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Input,
  InputChangeEvent,
  NumericTextBox,
} from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import CustomInput from "../../../components/custom/form/CustomInput";
import useAuth from "../../../hooks/useAuth";
import customerService from "../../../services/customer.service";
import { SMTPServerDetail } from "../../../types/customer/SMTPServerDetail";
import emailService from "../../../services/email.service";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { EmailValidator } from "../../../utils/validator";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useSwal from "../../../hooks/useSwal";
import Swal from "sweetalert2";
import useMasterData from "../../../hooks/useMasterData";

const EmailServerSettings = () => {
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const formRef = useRef<any>();
  const [smtpServerDetails, setSmtpServerDetails] =
    useState<SMTPServerDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [configuring, setConfiguring] = useState<boolean>(false);
  const [managedBySystem, setManagedBySystem] = useState<boolean>(false);
  const [upsertingServerDetails, setUpsertingServerDetails] =
    useState<boolean>(false);
  const [deletingServerDetails, setDeletingServerDetails] =
    useState<boolean>(false);
  const [sendingTestEmail, setSendingTestEmail] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "EmailServerSettings"
    ]
  );
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "EmailServerSettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const fetchSMTPDetails = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId) {
        try {
          setLoading(true);
          const resp = await customerService.getCustomerSMTPServerDetails(
            customerId
          );
          setSmtpServerDetails(resp);
          setManagedBySystem(false);
        } catch (err) {
          if (err instanceof AxiosError) {
            console.error(err);
            if (
              err.response?.data?.error?.includes(
                "CUSTOMER_SMTP_SERVER_NOT_FOUND"
              )
            ) {
              setManagedBySystem(true);
            } else {
              setError(err.response?.data?.error ?? err.message);
            }
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSMTPDetails();
  }, [auth?.tokenPayload?.CustomerId]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "EmailServerSettings"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "EmailServerSettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("EmailServerSettings");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const toggleConfiguring = () => {
    setConfiguring((prevState) => !prevState);
  };

  const onUserConfiguringCancelHandler = () => {
    setConfiguring((prevState) => !prevState);
    const form = formRef.current as Form;
    form.resetForm();
  };

  const smtpConfigureSubmitHandler = async (dataItem: {
    [name: string]: any;
  }) => {
    try {
      setUpsertingServerDetails(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId) {
        let updatedServerDetails: SMTPServerDetail = {
          id: smtpServerDetails ? smtpServerDetails.id : 0,
          host: dataItem.host,
          port: dataItem.port,
          username: dataItem.username,
          password: dataItem.password,
          notificationDisplayName: dataItem.notificationDisplayName,
          notificationEmailAddress: dataItem.notificationEmailAddress,
        };
        let updatedSMTPServer: SMTPServerDetail =
          await customerService.upsertCustomerSMTPServerDetails(
            customerId,
            updatedServerDetails
          );
        setSmtpServerDetails(updatedSMTPServer);
        
        setConfiguring(false);
        setManagedBySystem(false);
        swal.fire({
          icon: "success",
          confirmButtonText: `${
            translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwtAltOkText", "Ok")
          }`,
          title: `${
            translationsLoading
              ? "SMTP Server Details configured"
              : fetchLabelKeyTranslation(
                  "SwtAltSuccessTitleOne",
                  "SMTP Server Details configured"
                )
          }`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${
            translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
          }`,
          text: `${err.message}`,
        });
      }
    } finally {
      setUpsertingServerDetails(false);
    }
  };

  const onUserDeleteSMTPServerDetails = async () => {
    try {
      setDeletingServerDetails(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId && smtpServerDetails) {
        await customerService.deleteCustomerSMTPServerDetails(
          customerId,
          smtpServerDetails.id
        );
        setSmtpServerDetails(undefined);
        setManagedBySystem(true);
        swal.fire({
          icon: "success",
          confirmButtonText: `${
            translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwtAltOkText", "Ok")
          }`,
          title: `${
            translationsLoading
              ? "Customer SMTP Server Details deleted"
              : fetchLabelKeyTranslation(
                  "SwtAltSuccessTitleTwo",
                  "Customer SMTP Server Details deleted"
                )
          }`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${
            translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
          }`,
          text: `${err.message}`,
        });
      }
    } finally {
      setDeletingServerDetails(false);
    }
  };

  const onUserSendTestEmail = async () => {
    const res = await swal.fire({
      icon: "info",
      title: translationsLoading
        ? "Send test email to make sure that everything is configured properly"
        : fetchLabelKeyTranslation(
            "SwtAltSendEmailTitle",
            "Send test email to make sure that everything is configured properly"
          ),
      showCancelButton: true,
      cancelButtonText: translationsLoading
        ? "Cancel"
        : fetchLabelKeyTranslation("SwtAltCancelText", "Cancel"),
      showLoaderOnConfirm: true,
      confirmButtonText: translationsLoading
        ? "Send"
        : fetchLabelKeyTranslation("SwtAltConfirmText", "Send"),
      text: translationsLoading
        ? "Email Reciepient Address"
        : fetchLabelKeyTranslation(
            "SwtAltSendEmailText",
            "Email Reciepient Address"
          ),
      input: "email",
      preConfirm: function (value) {
        if (!EmailValidator(value)) {
          Swal.showValidationMessage(
            translationsLoading
              ? "Please provide a valid email address"
              : fetchLabelKeyTranslation(
                  "SwtAltValidationMessage",
                  "Please provide a valid email address"
                )
          );
          return undefined;
        } else {
          return value;
        }
      },
      backdrop: true,
      allowOutsideClick: () => !Swal.isLoading(),
    });

    console.log(res.value);
    console.log(res);

    if (res.isConfirmed) {
      const form = formRef.current as Form;
      try {
        setSendingTestEmail(true);
        const customerId = auth?.tokenPayload?.CustomerId;
        if (customerId && res.value) {
          const resp = await emailService.sendTestCustomerEmail(customerId, {
            host: form.values.host,
            port: form.values.port,
            username: form.values.username,
            password: form.values.password,
            notificationDisplayName: form.values.notificationDisplayName,
            notificationEmailAddress: form.values.notificationEmailAddress,
            receipientEmailAddress: res.value,
          });
          swal.fire({
            icon: "success",
            confirmButtonText: translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwtAltOkText", "Ok"),
            title: `${resp}`,
          });
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          console.error(err);
          swal.fire({
            icon: "error",
            title: translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
            text: `${
              translationsLoading
                ? "Unable to send email using given SMTP settings."
                : fetchLabelKeyTranslation(
                    "SwtAltErrorTextOne",
                    "Unable to send email using given SMTP settings."
                  )
            }`,
          });
        }
      } finally {
        setSendingTestEmail(false);
      }
    }
  };

  const inputFieldValidator = (value: string) => {
    if (!value || value.trim().length === 0) {
      return `${
        translationsLoading
          ? "This field is mandatory."
          : fetchLabelKeyTranslation(
              "EmailServerSettingsValidationMssg",
              "This field is mandatory."
            )
      }`;
    }
    return "";
  };

  const compareState = () => {
    const form = formRef.current as Form;

    if (
      smtpServerDetails?.host == form.values.host &&
      smtpServerDetails?.port == form.values.port &&
      smtpServerDetails?.username == form.values.username &&
      smtpServerDetails?.password == form.values.password &&
      smtpServerDetails?.notificationEmailAddress ==
        form.values.notificationEmailAddress &&
      smtpServerDetails?.notificationDisplayName ==
        form.values.notificationDisplayName
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <div className="m-b-10">
      <div className="row">
        {sendingTestEmail && (
          <LoadingOverlay
            customStyle={{ position: "fixed", marginTop: "55px" }}
            themeColor={"light"}
            size={"medium"}
            loadingText={`Sending Email...`}
          />
        )}
        <div className="col-md-12">
          <div className="trk-container emlBox">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {translationsLoading
                    ? "Email Account Details"
                    : fetchLabelKeyTranslation(
                        "EmailServerSettingsDetails",
                        "Email Account Details"
                      )}
                </span>
                <span className="float-right">
                  {smtpServerDetails && !configuring && (
                    <Button
                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                        masterData?.telepoDisableConfig?.systemSetting
                          .emailAccount.disableChanges
                          ? "disabledBtn"
                          : "border-primary"
                      }`}
                      style={{ height: "26px" }}
                      onClick={toggleConfiguring}
                      disabled={
                        masterData?.telepoDisableConfig?.systemSetting
                          .emailAccount.disableChanges
                      }
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Edit"
                          : fetchLabelKeyTranslation(
                              "EmailServerSettingsEditBtnText",
                              "Edit"
                            )}
                      </span>
                      <i className="bi bi-pencil"></i>
                    </Button>
                  )}
                  {smtpServerDetails && !configuring && (
                    <Button
                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                        masterData?.telepoDisableConfig?.systemSetting
                          .emailAccount.disableChanges
                          ? "disabledBtn"
                          : "border-primary"
                      }`}
                      style={{ height: "26px" }}
                      onClick={onUserDeleteSMTPServerDetails}
                      disabled={
                        masterData?.telepoDisableConfig?.systemSetting
                          .emailAccount.disableChanges
                      }
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Delete"
                          : fetchLabelKeyTranslation(
                              "EmailServerSettingsDeleteBtnText",
                              "Delete"
                            )}
                      </span>
                      {deletingServerDetails ? (
                        <Loader
                          type={"infinite-spinner"}
                          size={"small"}
                          themeColor={"primary"}
                        />
                      ) : (
                        <i className="bi bi-trash3-fill"></i>
                      )}
                    </Button>
                  )}
                </span>
              </div>
              {loading && (
                <div className="m-t-30 text-center">
                  <Loader type={"infinite-spinner"} />
                </div>
              )}
              {!configuring && (error || managedBySystem) && (
                <div className="float-left w-100 m-t-10">
                  <div className="selectScorecard">
                    {error && (
                      <div className="text-center p-10">
                        <span className="tx-red">{error}</span>
                      </div>
                    )}
                    {!smtpServerDetails && !error && (
                      <div>
                        <div className="text-center p-10">
                          <span className="tx-red">
                            {translationsLoading
                              ? "Your SMTP Server details are being managed by your Service Provider. To use your own SMTP Server, please configure your SMTP Server details."
                              : fetchLabelKeyTranslation(
                                  "EmailServerSettingsInfo",
                                  "Your SMTP Server details are being managed by your Service Provider. To use your own SMTP Server, please configure your SMTP Server details."
                                )}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          <Button
                            onClick={toggleConfiguring}
                            className={`btn bg-primary text-white ${
                              masterData?.telepoDisableConfig?.systemSetting
                                .emailAccount.disableChanges
                                ? "disabledBtn"
                                : ""
                            }`}
                            style={{ height: "40px", width: "150px" }}
                            disabled={
                              masterData?.telepoDisableConfig?.systemSetting
                                .emailAccount.disableChanges
                            }
                          >
                            {translationsLoading
                              ? "Configure"
                              : fetchLabelKeyTranslation(
                                  "EmailServerSettingsConfigBtnText",
                                  "Configure"
                                )}
                            <i className="bi bi-gear-wide-connected fs-20 m-l-8"></i>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!loading && !error && (smtpServerDetails || configuring) && (
                <Form
                  onSubmit={smtpConfigureSubmitHandler}
                  ref={formRef}
                  initialValues={{
                    host: smtpServerDetails?.host,
                    port: smtpServerDetails?.port,
                    username: smtpServerDetails?.username,
                    password: smtpServerDetails?.password,
                    notificationEmailAddress:
                      smtpServerDetails?.notificationEmailAddress,
                    notificationDisplayName:
                      smtpServerDetails?.notificationDisplayName,
                  }}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Host"
                                : fetchLabelKeyTranslation(
                                    "EmailServerSettingsHostField",
                                    "Host"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!configuring ? (
                                <span>{smtpServerDetails?.host}</span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"host"}
                                  name={"host"}
                                  required={true}
                                  placeholder={translationsLoading
                                    ? "Host"
                                    : fetchLabelKeyTranslation(
                                        "EmailServerSettingsHostField",
                                        "Host"
                                      )}
                                  value={formRenderProps.valueGetter("host")}
                                  component={CustomInput}
                                  validator={inputFieldValidator}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Port"
                                : fetchLabelKeyTranslation(
                                    "EmailServerSettingsPortField",
                                    "Port"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!configuring ? (
                                <span>{smtpServerDetails?.port}</span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"port"}
                                  name={"port"}
                                  value={formRenderProps.valueGetter("port")}
                                  spinners={false}
                                  min={0}
                                  max={1023}
                                  required={true}
                                  placeholder={translationsLoading
                                    ? "SMTP Port"
                                    : fetchLabelKeyTranslation(
                                        "EmailServerSettingsPortPlaceholder",
                                        "SMTP Port"
                                      )}
                                  component={NumericTextBox}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "User Name"
                                : fetchLabelKeyTranslation(
                                    "EmailServerSettingsUsernameField",
                                    "User Name"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!configuring ? (
                                <span>{smtpServerDetails?.username}</span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"username"}
                                  name={"username"}
                                  value={formRenderProps.valueGetter(
                                    "username"
                                  )}
                                  required={true}
                                  placeholder={translationsLoading
                                    ? "SMTP Server User Name"
                                    : fetchLabelKeyTranslation(
                                        "EmailServerSettingsUsernamePlaceholder",
                                        "SMTP Server User Name"
                                      )}
                                  component={CustomInput}
                                  validator={inputFieldValidator}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Password"
                                : fetchLabelKeyTranslation(
                                    "EmailServerSettingsPasswordField",
                                    "Password"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!configuring ? (
                                <span>{`\u25CF`.repeat(20)}</span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"password"}
                                  name={"password"}
                                  type={"password"}
                                  value={formRenderProps.valueGetter(
                                    "password"
                                  )}
                                  required={true}
                                  placeholder={translationsLoading
                                    ? "SMTP Server Password"
                                    : fetchLabelKeyTranslation(
                                        "EmailServerSettingsPasswordPlaceholder",
                                        "SMTP Server Password"
                                      )}
                                  component={CustomInput}
                                  validator={inputFieldValidator}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {translationsLoading
                                ? "Notification Email Address"
                                : fetchLabelKeyTranslation(
                                    "EmailServerSettingsEmailAddressField",
                                    "Notification Email Address"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              <span>
                                {!configuring ? (
                                  <span>
                                    {
                                      smtpServerDetails?.notificationEmailAddress
                                    }
                                  </span>
                                ) : (
                                  <Field
                                    className="textInput"
                                    id={"notificationEmailAddress"}
                                    name={"notificationEmailAddress"}
                                    type={"email"}
                                    value={formRenderProps.valueGetter(
                                      "notificationEmailAddress"
                                    )}
                                    required={true}
                                    placeholder={translationsLoading
                                      ? "Notification Email Address"
                                      : fetchLabelKeyTranslation(
                                          "EmailServerSettingsEmailAddressField",
                                          "Notification Email Address"
                                        )}
                                    component={CustomInput}
                                    validator={inputFieldValidator}
                                    onChange={compareState}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {translationsLoading
                                ? "Notification Display Name"
                                : fetchLabelKeyTranslation(
                                    "EmailServerSettingsDisplayNameField",
                                    "Notification Display Name"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!configuring ? (
                                <span>
                                  {smtpServerDetails?.notificationDisplayName}
                                </span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"notificationDisplayName"}
                                  name={"notificationDisplayName"}
                                  value={formRenderProps.valueGetter(
                                    "notificationDisplayName"
                                  )}
                                  required={true}
                                  placeholder={translationsLoading
                                    ? "Notification Display Name"
                                    : fetchLabelKeyTranslation(
                                        "EmailServerSettingsDisplayNameField",
                                        "Notification Display Name"
                                      )}
                                  component={CustomInput}
                                  validator={inputFieldValidator}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {configuring && (
                          <div className="row m-t-8">
                            <div className="col-md-12 text-right">
                              <Button
                                className="btn bg-black-5 m-r-5"
                                onClick={onUserConfiguringCancelHandler}
                              >
                                {translationsLoading
                                  ? "Cancel"
                                  : fetchLabelKeyTranslation(
                                      "EmailServerSettingsBtnCancelText",
                                      "Cancel"
                                    )}
                              </Button>
                              <Button
                                type={"button"}
                                className={`btn bg-primary text-white m-r-5 ${
                                  !formRenderProps.valid ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .emailAccount.disableChanges
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                disabled={
                                  !formRenderProps.valid ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .emailAccount.disableChanges
                                }
                                onClick={onUserSendTestEmail}
                              >
                                {translationsLoading
                                  ? "Send Test Email"
                                  : fetchLabelKeyTranslation(
                                      "EmailServerSettingsBtnSendEmailText",
                                      "Send Test Email"
                                    )}
                              </Button>
                              <Button
                                type={"submit"}
                                className={`btn bg-primary text-white ${
                                  !formRenderProps.allowSubmit ||
                                  !formRenderProps.modified ||
                                  !formRenderProps.valid ||
                                  buttonStateTracker ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .emailAccount.disableChanges
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                disabled={
                                  !formRenderProps.allowSubmit ||
                                  !formRenderProps.modified ||
                                  !formRenderProps.valid ||
                                  buttonStateTracker ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .emailAccount.disableChanges
                                }
                              >
                                {upsertingServerDetails ? (
                                  <Loader
                                    size={"small"}
                                    type={"infinite-spinner"}
                                    themeColor={"light"}
                                  />
                                ) : (
                                  <>
                                    {translationsLoading
                                      ? "Update"
                                      : fetchLabelKeyTranslation(
                                          "UpdateButton",
                                          "Update"
                                        )}
                                  </>
                                )}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </FormElement>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailServerSettings;
