import { DeviceAllocation } from "../types/device/deviceAllocation";
import { PlatformUserDetail } from "../types/user";
import { axiosWithAuth } from "../utils/customAxios";

const deviceService = {
  getAllCustomerDevice: async (): Promise<DeviceAllocation[]> => {
    const response = await axiosWithAuth.get(`/device`);
    const devices: DeviceAllocation[] = response.data;
    return devices;
  },
};

export default deviceService;
