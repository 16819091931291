import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from "../../constants";
import authService from "../../services/auth.service";
import LoadingOverlay from "../../components/LoadingOverlay";
import { AxiosError } from "axios";
import useBranding from "../../hooks/useBranding";
import defaultLogo from "../../assets/images/logo.png";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { appUrl } from "../..";
import { LoginUser } from "../../types/auth/LoginUser";
import { Dictionary } from "../../types/Dictionary";
import useLocale from "../../hooks/useLocale";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import CustomInput from "../../components/custom/form/CustomInput";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import CustomComboBox from "../../components/custom/form/CustomComboBox";
import { PairCustomerNameAndId } from "../../types/auth/PairCustomerNameAndId";

const Login: React.FC = () => {
  const brandingCtx = useBranding();
  const localeCtx = useLocale();
  const swal = useSwal();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loginUserToken, setLoginUserToken] = useState<string>();
  const [customerList, setCustomerList] = useState<PairCustomerNameAndId[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["Login"]);

  const from =
    location.state?.from?.pathname + location.state?.from?.search || "/home";

  const username = searchParams.get("username");
  const appToken = searchParams.get("appToken");
  const language = searchParams.get("language");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Login"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("Login");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["Login"]
      );
      localeCtx?.setPreviousAppLocale("CallSummary");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    const originalRedirectUri = searchParams.get("originalRedirectUri");
      let authAppBaseUrl = AUTH_BASE_URL;

      if (brandingCtx?.branding?.authAppConfiguration?.authClientAppUrl) {
        authAppBaseUrl =
          brandingCtx.branding.authAppConfiguration.authClientAppUrl;
      }
      let authAppClientId = AUTH_CLIENT_ID;
      if (brandingCtx?.branding?.authAppConfiguration?.authAppClientId) {
        authAppClientId =
          brandingCtx.branding.authAppConfiguration.authAppClientId;
      }
      const authAppLoginUrl = `${authAppBaseUrl}/login?clientId=${authAppClientId}&originalRedirectUri=${
        originalRedirectUri ?? from
      }`;
    if (brandingCtx?.branding) {
      if (username && appToken) {
        setLoading(true);
        authService
          .verifycredentials({ username, appToken })
          .then((tempTokenResponse) => {
            if (tempTokenResponse.customerList.length > 1) {
              setLoginUserToken(tempTokenResponse.token);
              setCustomerList(tempTokenResponse.customerList);
              setShowForm(true);
            } else {
              if (tempTokenResponse.customerList.length > 0) {
                setSelectedCustomerId(tempTokenResponse.customerList[0].id);
                const loginUser: LoginUser = {
                  username: username,
                  tempToken: tempTokenResponse.token,
                  customerId: tempTokenResponse.customerList[0].id,
                };
                authService.login(loginUser).then((token) => {
                  if (token && token.trim().length > 0) {
                    console.log("Token: ", token);
                    auth?.setUserAuthToken(token);
                    navigate(
                      originalRedirectUri ?? `/home?language=${language}`,
                      { replace: true }
                    );
                  }
                });
              }
            }
          })
          .catch((error) => {
            auth?.setUserAuthToken(undefined);
            if (error instanceof AxiosError) {
              if(error.response?.status === 400){
                setError("UserNotFound");
              }
              else if(error.response?.status === 401){
                setError("Unauthorized");
              }
              if (error.response?.status === 403) {
                setError("UserLoginDisabled");
              }
              else if(error.response?.status === 500){
                setError("InternalServerError");
              }
              else{
                setError("InternalServerError");
              }
            }
            //window.location.replace(authAppLoginUrl);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (auth?.accessToken) {
        navigate(from, { replace: true });
      } else {
        window.location.replace(authAppLoginUrl);
      }
    }
  }, [brandingCtx?.brandingLoaded]);

  const onErrorHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }

    try {
      window.location.replace(
        `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const submitHandler = async () => {
    const originalRedirectUri = searchParams.get("originalRedirectUri");
    const loginUser: LoginUser = {
      username: username ?? "",
      tempToken: loginUserToken ?? "",
      customerId: selectedCustomerId,
    };
    authService.login(loginUser).then((token) => {
      if (token && token.trim().length > 0) {
        console.log("Token: ", token);
        auth?.setUserAuthToken(token);
        navigate(originalRedirectUri ?? `/home?language=${language}`, {
          replace: true,
        });
      }
    });
  };

  const backHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }
    window.location.replace(
      `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login`
    );
    try {
      console.log("Loggin out...");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {loading && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
                loadingText={"Redirecting..."}
              />
            )}
            {showForm && (
              <div className="formGroup h-100">
                <div
                  className="formInrG cardEffect"
                  style={{ position: "relative" }}
                >
                  {brandingCtx?.branding?.logos.find(
                    (l) => l.name === "HeaderPrimaryLogo"
                  )?.logoImageUrl && (
                    <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                      {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                      <img
                        src={
                          brandingCtx?.branding?.logos.find(
                            (l) => l.name === "HeaderPrimaryLogo"
                          )?.logoImageUrl
                        }
                        alt="Logo"
                      />
                    </div>
                  )}
                  <Form
                    initialValues={{
                      customer: undefined,
                    }}
                    onSubmit={submitHandler}
                    render={(formRenderProps) => (
                      <FormElement style={{ maxWidth: "100%" }}>
                        <fieldset className={"k-form-fieldset"}>
                          <legend
                            className={"k-form-legend fs-18 font-weight-semi"}
                            style={{ textTransform: "none" }}
                          >
                            {translationsLoading
                              ? "Please select the customer"
                              : fetchLabelKeyTranslation(
                                  "LoginFormTitle",
                                  "Please select the customer"
                                )}
                          </legend>
                          <div className="m-b-15">
                            <Field
                              id="customer"
                              name={"Customer"}
                              className="comboBoxInput"
                              placeholder="Please select ..."
                              data={customerList ?? []}
                              textField="customerName"
                              value={formRenderProps.valueGetter("customer")}
                              component={CustomComboBox}
                              dataItemKey="id"
                              sorting={true}
                              onChange={(e) => {
                                setSelectedCustomerId(e.value.id);
                              }}
                            />
                          </div>
                        </fieldset>
                        <div className="k-form-buttons d-flex justify-content-center m-t-15">
                          <Button
                            type={"submit"}
                            style={{
                              width: "100%",
                              textTransform: "uppercase",
                            }}
                            className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 ${
                              selectedCustomerId === 0 && "disabledBtn"
                            }`}
                            disabled={selectedCustomerId === 0}
                          >
                            <i className="bi bi-lock fs-14 p-r-5"></i>
                            {translationsLoading
                              ? "Submit"
                              : fetchLabelKeyTranslation(
                                  "SubmitBtnText",
                                  "Submit"
                                )}
                          </Button>
                        </div>
                      </FormElement>
                    )}
                  />
                  <p className="text-center text-muted m-t-6 m-b-0 fs-16">
                    {translationsLoading
                      ? "Go"
                      : fetchLabelKeyTranslation("GoText", "Go")}{" "}
                    <span
                      onClick={backHandler}
                      className="forgotPasswordLbl cursor-pointer btn-link"
                    >
                      {translationsLoading
                        ? "Back"
                        : fetchLabelKeyTranslation("BackText", "Back")}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {!showForm && !loading && error && (
              <div className="loginBg h-100 p-l-15 p-r-15">
                <div className="row m-b-20 h-100">
                  <div className="col-md-12 h-100">
                    <div className="formGroup h-100">
                      <div
                        className="formInrG cardEffect"
                        style={{ position: "relative" }}
                      >
                        <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                          <a className="navbar-logo" href="" title="Logo">
                            <img
                              src={
                                brandingCtx?.branding?.logos.find(
                                  (l) => l.name === "HeaderPrimaryLogo"
                                )?.logoImageUrl ?? defaultLogo
                              }
                              alt="Logo"
                            />
                          </a>
                        </div>
                        <Error className="fs-18">
                          {translationsLoading
                              ? error
                              : fetchLabelKeyTranslation(error, error)
                          }
                        </Error>
                        <div className="text-center p-t-5 p-b-15">
                          <Button
                            className={`btn bg-primary text-white `}
                            onClick={onErrorHandler}
                          >
                            {translationsLoading
                              ? "Back to Login Page"
                              : fetchLabelKeyTranslation(
                                  "BackButtonText",
                                  "Back to Login Page"
                                )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
