import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import AccountSettings from "./AccountSettings";
import EmailServerSettings from "./EmailServerSettings";
import PlatformLicenses from "./PlatformLicenses";
import SecurityPrivacySettings from "./SecurityPrivacySettings";
import StorageSettings from "./StorageSettings";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useAuth from "../../../hooks/useAuth";
import { AccessPermissionEnum } from "../../../enums";
import NotFoundError from "../../error/NotFoundError";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import AuditLogSettings from "./audit-log-settings/AuditLogSettings";
import RecordingPolicySettings from "./recording-policy/RecordingPolicySettings";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CRMSetting from "./CRMSetting";
import APIAccess from "./api-access/APIAccess";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { EntityKey } from "../../../types/master-data/EntityKey";
import React from "react";

interface SystemSettingsProps {}
const SystemSettings: React.FC<SystemSettingsProps> = ({}) => {
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [selected, setSelected] = useState<number>(0);
  const [pageFreezeLoader, setPageFreezeLoader] = useState<boolean>(false);

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["SystemSettings"]);

  interface ISystemSettingsDropdown extends EntityKey {
    id: number;
    title: string;
  }
  
  const mobileDropdownData: ISystemSettingsDropdown[] = [
    {
      id: 0,
      title: "Account",
      localizationKey: "SystemSettingsAccountTab",
    },
    {
      id: 1,
      title: "Storage",
      localizationKey: "SystemSettingsStorageTab",
    },
    {
      id: 2,
      title: "License",
      localizationKey: "SystemSettingsLicenseTab",
    },
    {
      id: 3,
      title: "Email Account",
      localizationKey: "SystemSettingsEmailTab",
    },
    {
      id: 4,
      title: "Security & Privacy",
      localizationKey: "SystemSettingsSecurityTab",
    },
    {
      id: 5,
      title: "Audit Logs",
      localizationKey: "SystemSettingsAuditTab",
    },
    {
      id: 6,
      title: "Recording Policy",
      localizationKey: "SystemSettingsRecordingPolicyTab",
    },
    {
      id: 7,
      title: "CRM",
      localizationKey: "SystemSettingsCRMTab", // TODO Add in localization
    }
  ];

  const mobileItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        {translationsLoading
          ? itemProps.dataItem.title
          : fetchLabelKeyTranslation(
              itemProps.dataItem.localizationKey ?? "",
              itemProps.dataItem.title
            )}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const mobileValueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: ISystemSettingsDropdown
  ) => {
    if (!value) {
      return element;
    }
    const currentlySelected = mobileDropdownData[selected];
    const children = [
      <span key={currentlySelected.title}>
        {translationsLoading
          ? currentlySelected.title
          : fetchLabelKeyTranslation(currentlySelected.localizationKey ?? "", currentlySelected.title)}
      </span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };
  
  interface IMobileSystemSettingsComponentProps {
    id: number
  }
  const MobileSystemSettingsComponent: React.FC<IMobileSystemSettingsComponentProps> = ({id}) => {
    switch(id) {
      case 0: return <AccountSettings />
      case 1: return <StorageSettings />
      case 2: return <PlatformLicenses />
      case 3: return <EmailServerSettings />
      case 4: return <SecurityPrivacySettings />
      case 5: return <AuditLogSettings
                        title={true}
                        search={true}
                        filters={true}
                        excludedColumns={[]}
                      />
      case 6: return <RecordingPolicySettings setPageFreezeLoader={setPageFreezeLoader}/>
      case 7: return <CRMSetting />
      default: return <AccountSettings />
    }
  }

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "SystemSettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("SystemSettings");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "SystemSettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("SystemSettings");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageAccount) ? (
        <UnauthorizedAccess />
      ) : (
        <>
         {translationsLoading && !pageFreezeLoader && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={fetchLabelKeyTranslation(
            "SwitchLanguageText",
            ""
          )}
        />
      )}
          {pageFreezeLoader && (
            <LoadingOverlay
              customStyle={{
                position: "fixed",
                marginTop: "55px",
                zIndex: "999999",
              }}
              themeColor={"light"}
              size={"medium"}
              loadingText={"Please wait..."}
            />
          )}
          <div className="m-b-20">
            <div className="row">
              <div className="col-md-12">
                <div className="card cardEffect">
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="detailHeader d-flex align-items-center">
                      <span className="fs-16 font-weight-semi p-r-20">
                        {`${
                          translationsLoading
                            ? "System Settings"
                            : fetchLabelKeyTranslation(
                                "SystemSettingsTitle",
                                "System Settings"
                              )
                        }`}
                      </span>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="settingData k-custom-tab p-t-20 p-b-20 systemSet settingDataMobile">
                          {/* Mobile View */}
                          <div className="tabGroupDropdown"> 
                            <DropDownList
                              style={{ width: "100%", marginBottom: "10px" }}
                              textField="title"
                              dataItemKey="id"
                              data={mobileDropdownData}
                              itemRender={mobileItemRender}
                              valueRender={mobileValueRender}
                              onChange={(e)=>{ setSelected(e.value?.id) }}
                              defaultValue={mobileDropdownData[selected]}
                            />
                            <MobileSystemSettingsComponent id={selected} />
                          </div>
                          <TabStrip
                            tabPosition={"left"}
                            selected={selected}
                            onSelect={handleSelect}
                            className="tabGroupData"
                          >
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Account"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsAccountTab",
                                        "Account"
                                      )
                                }`}
                              >
                                <AccountSettings />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Storage"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsStorageTab",
                                        "Storage"
                                      )
                                }`}
                              >
                                <StorageSettings />
                              </TabStripTab>
                            )}
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "License"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsLicenseTab",
                                        "License"
                                      )
                                }`}
                              >
                                <PlatformLicenses />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Email Account"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsEmailTab",
                                        "Email Account"
                                      )
                                }`}
                              >
                                <EmailServerSettings />
                              </TabStripTab>
                            )}
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Security & Privacy"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsSecurityTab",
                                        "Security & Privacy"
                                      )
                                }`}
                              >
                                <SecurityPrivacySettings />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Audit Logs"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsAuditTab",
                                        "Audit Logs"
                                      )
                                }`}
                              >
                                <AuditLogSettings
                                  title={true}
                                  search={true}
                                  filters={true}
                                  excludedColumns={[]}
                                />
                              </TabStripTab>
                            )}
                            <TabStripTab
                              title={`${
                                translationsLoading
                                  ? "Recording Policy"
                                  : fetchLabelKeyTranslation(
                                      "SystemSettingsRecordingPolicyTab",
                                      "Recording Policy"
                                    )
                              }`}
                            >
                              <RecordingPolicySettings
                                setPageFreezeLoader={setPageFreezeLoader}
                              />
                            </TabStripTab>
                            <TabStripTab title="CRM">
                              <CRMSetting />
                            </TabStripTab>
                            <TabStripTab title={`${
                                translationsLoading
                                  ? "API Access"
                                  : fetchLabelKeyTranslation(
                                      "SystemSettingsAPIAccessTab",
                                      "API Access"
                                    )
                              }`}>
                              <APIAccess setPageFreezeLoader={setPageFreezeLoader} />
                            </TabStripTab>
                          </TabStrip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SystemSettings;
