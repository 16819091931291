import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ListItemProps, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Stepper, StepperChangeEvent } from "@progress/kendo-react-layout";
import {
  lockIcon,
  fileConfigIcon,
  userIcon,
  fileReportIcon,
} from "@progress/kendo-svg-icons";
import { useEffect, useState } from "react";
import CustomMultiSelect from "../../components/custom/form/CustomMultiSelect";
import useMasterData from "../../hooks/useMasterData";
import { TinyUser } from "../../types/user";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import Swal from "sweetalert2";
import { PlatformDetail } from "../../types/customer";
import CustomUserGridInfo from "../../components/custom/grid/CustomUserGridInfo";
import React from "react";
import useAuth from "../../hooks/useAuth";
import customerService from "../../services/customer.service";
import { AxiosError } from "axios";
import { CustomerPlatformStatus } from "../../types/customer/CustomerPlatformStatus";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { UpdateMsTeamsRecordingPolicyStatus } from "../../types/customer/UpdateMsTeamsRecordingPolicyStatus";
import { Link } from "react-router-dom";

interface MsTeamsProps {
  platformDetail: PlatformDetail
}

const MsTeams: React.FC<MsTeamsProps> = ({platformDetail}) => {
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const auth = useAuth();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "CustomerPlatformMsTeams"
    ]
  );

  const [prerequisiteScript, setPrerequisiteScript] = useState<string>("");
  const [applicationInstanceCreationScript, setApplicationInstanceCreationScript] = useState<string>("");
  const [applicationInstanceValidationScript, setApplicationInstanceValidationScript] = useState<string>("");
  const [recordingPolicyCreationScript, setRecordingPolicyCreationScript] = useState<string>("");
  const [recordingPolicyValidationScript, setRecordingPolicyValidationScript] = useState<string>("");
  const [assigningRecordingPolicyToUsersScript, setAssigningRecordingPolicyToUsersScript] = useState<string>("");
  const [assigningRecordingPolicyToAllUsersScript, setAssigningRecordingPolicyToAllUsersScript] = useState<string>("");
  const [validateAssignedUsersScript, setValidateAssignedUsersScript] = useState<string>("");

  const [loader, setLoader] = useState<boolean>(false);
  const [platformStatus, setPlatformStatus] = useState<CustomerPlatformStatus | null>(null);
  const [assignedUsers, setAssignedUsers] = useState<TinyUser[]>([]);
  const [assignToAllUsers, setAssignToAllUsers] = useState<boolean>(false);
  const [stepNumber, setStepNumber] = React.useState<number>(0);

  const stepperItemList = [
    { svgIcon: lockIcon, label: "Admin Consent" },
    { svgIcon: fileReportIcon, label: "Application Instance" },
    { svgIcon: fileConfigIcon, label: "Recording Policy" },
    { svgIcon: userIcon, label: "Manage User" },
  ];

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "CustomerPlatformMsTeams"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getCustomerPlatformStatus();
    fetchPrerequisiteScript();
    fetchApplicationInstanceCreationScript();
    fetchApplicationInstanceValidationScript();
    fetchRecordingPolicyCreationScript();
    fetchRecordingPolicyValidationScript();
    fetchAssigningRecordingPolicyToUsersScript();
    fetchAssigningRecordingPolicyToAllUsersScript();
    fetchValidateAssignedUsersScript();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "CustomerPlatformMsTeams"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "CustomerPlatformMsTeams"
        ]
      );
      localeCtx?.setPreviousAppLocale("CustomerPlatformMsTeams");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const getCustomerPlatformStatus = async () => {
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        const _platformStatus = await customerService.GetCustomerPlatformStatus(customerId, "MsTeams");
        if(_platformStatus){
          setPlatformStatus(_platformStatus);
          if(_platformStatus.msTeamsPlatformStatus?.isAdminConsentGranted === false){
            setStepNumber(0);
          }
          else if(_platformStatus.msTeamsPlatformStatus?.isPolicyApplicationInstanceCreated === false){
            setStepNumber(1);
          }
          else if(_platformStatus.msTeamsPlatformStatus?.isRecordingPolicyCreated === false){
            setStepNumber(2);
          }
          else{
            setStepNumber(3);
          }
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          setPlatformStatus(null);
          console.log(err);
        }
      }
    }
  }

  const resendMsAdminConsentEmail = async (e: any) => {
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to resend admin consent email!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Resend",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoader(true);
          var response = await customerService.ResendMsAdminConsentEmail(customerId, "MsTeams");
          if(response === true){
            Swal.fire({
              title: "Success!",
              text: "Admin consent email sent!",
              icon: "success"
            });
          }
          setLoader(false);
        }
      });
    }
  }

  const handlePolicyCreatedMark = async (e: CheckboxChangeEvent) => {
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: e.value ? "You have created recording policy successfully!" : "You have not created recording policy successfully!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoader(true);
          var _updateMsTeamsRecordingPolicyStatus: UpdateMsTeamsRecordingPolicyStatus = {
            isPolicyApplicationInstanceCreated: platformStatus?.msTeamsPlatformStatus?.isPolicyApplicationInstanceCreated ?? false,
            isRecordingPolicyCreated: e.value
          }
          var response = await customerService.UpdateMsTeamsPolicyStatus(customerId, _updateMsTeamsRecordingPolicyStatus);
          if(response){
            let _tempStatus = {...platformStatus};
            if(_tempStatus && _tempStatus.msTeamsPlatformStatus){
              _tempStatus.msTeamsPlatformStatus.isRecordingPolicyCreated = e.value;
            }
            setPlatformStatus(_tempStatus);
          }
          setLoader(false);
        }
      });
    }
  }

  const handleApplicationInstanceCreatedMark = async (e: CheckboxChangeEvent) => {
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: e.value ? "You have created application instance successfully!" : "You have not created application instance successfully!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoader(true);
          var _updateMsTeamsRecordingPolicyStatus: UpdateMsTeamsRecordingPolicyStatus = {
            isPolicyApplicationInstanceCreated: e.value,
            isRecordingPolicyCreated: platformStatus?.msTeamsPlatformStatus?.isRecordingPolicyCreated ?? false
          }
          var response = await customerService.UpdateMsTeamsPolicyStatus(customerId, _updateMsTeamsRecordingPolicyStatus);
          if(response){
            let _tempStatus = {...platformStatus};
            if(_tempStatus && _tempStatus.msTeamsPlatformStatus){
              _tempStatus.msTeamsPlatformStatus.isPolicyApplicationInstanceCreated = e.value;
            }
            setPlatformStatus(_tempStatus);
          }
          setLoader(false);
        }
      });
    }
  }

  const fetchPrerequisiteScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/PrerequisiteScript.txt"
    );
    const _prerequisiteScript = await response.text();
    setPrerequisiteScript(_prerequisiteScript);
  };

  const fetchApplicationInstanceCreationScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/ApplicationInstanceCreationScript.txt"
    );
    const _applicationInstanceCreationScript = await response.text();
    setApplicationInstanceCreationScript(_applicationInstanceCreationScript);
  }

  const fetchApplicationInstanceValidationScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/ApplicationInstanceValidationScript.txt"
    );
    const _applicationInstanceValidationScript = await response.text();
    setApplicationInstanceValidationScript(_applicationInstanceValidationScript);
  }

  const fetchRecordingPolicyCreationScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/RecordingPolicyCreationScript.txt"
    );
    const _RecordingPolicyCreationScript = await response.text();
    setRecordingPolicyCreationScript(_RecordingPolicyCreationScript);
  };

  const fetchRecordingPolicyValidationScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/RecordingPolicyValidationScript.txt"
    );
    const _RecordingPolicyValidationScript = await response.text();
    setRecordingPolicyValidationScript(_RecordingPolicyValidationScript);
  };

  const fetchAssigningRecordingPolicyToUsersScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/AssigningRecordingPolicyToUsersScript.txt"
    );
    const _AssigningRecordingPolicyToUsersScript = await response.text();
    setAssigningRecordingPolicyToUsersScript(_AssigningRecordingPolicyToUsersScript);
  };

  const fetchAssigningRecordingPolicyToAllUsersScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/AssigningRecordingPolicyToAllUsersScript.txt"
    );
    const _AssigningRecordingPolicyToAllUsersScript = await response.text();
    setAssigningRecordingPolicyToAllUsersScript(_AssigningRecordingPolicyToAllUsersScript);
  };

  const fetchValidateAssignedUsersScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/ValidateAssignedRecordingPolicyUsers.txt"
    );
    const _ValidateAssignedUsersScript = await response.text();
    setValidateAssignedUsersScript(_ValidateAssignedUsersScript);
  };

  const handleStepChange = (e: StepperChangeEvent) => {
    setStepNumber(e.value);
  };

  const getUpdatedAssigningRecordingPolicyToUsersScript = () => {
    return assigningRecordingPolicyToUsersScript.replace(`"<username@domain.com>"`, assignedUsers.map(u => `"` + u.email + `"`).join(', '));
  }

  const downloadScript = (filename: string, text: string) => {
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: 0,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const PolicyScriptComponent = (titleName: string, description: string, policyScript: string, fileName: string) => {
    return (
      <div className="float-left w-100 p-t-5 p-l-5 p-r-5 m-b-20">
        <div className="visibleDataDiv float-left w-100">
          <div className="row">
            <div className="col-md-12">
              <div className="topCodeBox float-left w-100">
                <div className="dropBox float-left">
                  <div className="p-l-10">
                    <i className="bi bi-record-fill"></i>
                    <span className="fw-bold p-l-5 p-r-10">{titleName}</span>
                    <span className="minTextLabel text-black-9">({description})</span>
                  </div>
                </div>
                <div className="codeBoxActionBtn float-right">
                  <Button
                    className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                    style={{ height: "26px" }}
                  >
                    <span
                      onClick={() => downloadScript(fileName, policyScript)}
                      className="p-r-2"
                    >
                      {translationsLoading
                        ? "Download"
                        : fetchLabelKeyTranslation(
                            "BtnDownload",
                            "Download"
                          )}
                    </span>
                    <i className="bi bi-download"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="codeData">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(policyScript);
                  }}
                  className="copCodeDataBtn"
                >
                  <span>
                    {translationsLoading
                      ? "Copy Code"
                      : fetchLabelKeyTranslation(
                          "BtnCopyCode",
                          "Copy Code"
                        )}
                  </span>
                  <i className="bi bi-files p-l-3"></i>
                </Button>
                <div
                  className="fs-13"
                  style={{
                    maxHeight: "200px",
                    overflow: "auto",
                    margin: "0 0 10px 0",
                  }}
                >
                  <p style={{ whiteSpace: "pre-line" }}>
                    {policyScript}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
        <span className="text-primary">MsTeams Platform</span>
        <span className="float-right">
          <Link
            to="/msTeamsFiles/RecordingPolicyTroubleshootGuide.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={`btn btn-link text-primary p-0 fs-14 iconBtn`}
          >
            <i className="bi bi-info-circle m-r-5"></i>
            {translationsLoading ? "Help Guide"
            : fetchLabelKeyTranslation(
                "TroubleshootLink",
                "Help Guide"
              )}
          </Link>
        </span>
      </div>
      <div className="trk-row border-bottom-solid border-w-1 border-black-1">
        <div className="trkCol">
          <div className="trkCol-h font-weight-semi">{`${
            translationsLoading
              ? "Tenant Id"
              : fetchLabelKeyTranslation("FieldTenantId", "Tenant Id")
          }`}</div>
          <div className="trkCol-dot">:</div>
          <div className="trkCol-p">{platformDetail.customerDetail.msTeamsTenantsGuid}</div>
        </div>
      </div>
      <div className="trk-row border-bottom-solid border-w-1 border-black-1">
        <div className="trkCol">
          <div className="trkCol-h font-weight-semi">{`${
            translationsLoading
              ? "Admin Email"
              : fetchLabelKeyTranslation("FieldAdminEmail", "Admin Email")
          }`}</div>
          <div className="trkCol-dot">:</div>
          <div className="trkCol-p">{platformDetail.customerDetail.msTeamsAdminEmailAddress}</div>
        </div>
      </div>
      <div className="accordionData float-left w-100 p-t-30 m-t-10 p-r-30">
        <div className="">
          <Stepper
            value={stepNumber}
            onChange={handleStepChange}
            items={stepperItemList}
          />
          <div className="dvcListCol m-l-10 m-t-20 p-t-20">
            {stepNumber === 0 && 
              <div className="">
                <span className="text-primary font-weight-semi fs-14 p-b-10 float-left w-100 d-flex align-items-center">
                  <span>Microsoft Teams Admin Consent</span>
                </span>
                <div className="minTextLabel text-black-9">
                Microsoft Teams admin consent refers to the process by which administrators grant permissions for Recording Bot 
                applications or services to access and integrate with Microsoft Teams on behalf of your organization. 
                Recording Bot application requires specific permissions to interact with Microsoft Teams APIs
                and access certain features or data.
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      {platformStatus?.msTeamsPlatformStatus?.isAdminConsentGranted ? 
                        <div className="float-left w-100 text-center">
                          <div className="errorTxt">
                            <div 
                              style={{
                                fontSize: "50px",
                                opacity: ".4",
                                padding: "20px 0 0px 0",
                              }}
                            >
                              <i className="bi bi-check-circle-fill tx-green"></i>
                            </div>
                            <div className="errorTxt-p fs-14 p-b-20">
                              Admin has granted the consent. Please move on to next step.
                            </div>
                          </div>
                        </div>
                        : 
                        <div className="p-t-20">
                          <p className="fs-13">Please 
                            {loader ? 
                              <Loader style={{padding: "0 10px 0 10px"}}/>
                              :
                              <span className="tx-link-button" onClick={resendMsAdminConsentEmail}> Resend </span> 
                            }
                            admin consent email to grant access or, 
                            <span className="tx-link-button" onClick={() => {
                              navigator.clipboard.writeText(platformStatus?.msTeamsPlatformStatus?.adminConsentUrl ?? "")
                              }}> Copy the Link </span> 
                            and share with your MsTeams admin to grant access.
                          </p>
                          <div className="float-left w-100 text-center">
                            <div className="errorTxt">
                              <div 
                                style={{
                                  fontSize: "50px",
                                  opacity: "0.4",
                                  padding: "20px 0 0px 0",
                                }}
                              >
                                <i className="bi bi-x-circle-fill tx-red"></i>
                              </div>
                              <div className="errorTxt-p fs-14 p-b-20">
                                Admin has not granted the consent.
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            {stepNumber === 1 && 
              <div className="">
                <span className="text-primary font-weight-semi fs-14 p-b-10 float-left w-100 d-flex align-items-center">
                  <span>Create Application Instance</span>
                </span>
                {PolicyScriptComponent("Prerequisite Script", "Script to install MicrosoftTeams module and login to MicrosoftTeams using Poweshell.", prerequisiteScript, "PrerequisiteScript.ps1")}
                {PolicyScriptComponent("Cs-Online Application Instance Creation Script", "Script to create new cs-online application instance for recording policy.", applicationInstanceCreationScript.replace("@@RecordingBotAppId@@", platformStatus?.msTeamsPlatformStatus?.botAppId ?? ""), "ApplicationInstanceCreationScript.ps1")}
                {PolicyScriptComponent("Validate Application Instance Script", "Script to get created application instance.", applicationInstanceValidationScript.replace("@@RecordingBotAppId@@", platformStatus?.msTeamsPlatformStatus?.botAppId ?? ""), "ApplicationInstanceValidationScript.ps1")}
                <div className="float-left w-100 p-t-20 p-l-10 p-r-5">
                  <Checkbox
                    value={platformStatus?.msTeamsPlatformStatus?.isPolicyApplicationInstanceCreated ?? false}
                    onChange={handleApplicationInstanceCreatedMark}
                  />
                  <span className="m-l-10">Please mark if you have created application instance successfully.</span>
                </div>
              </div>
            }
            {stepNumber === 2 && 
              <div className="">
                <span className="text-primary font-weight-semi fs-14 p-b-10 float-left w-100 d-flex align-items-center">
                  <span>Create Recording Policy</span>
                </span>
                {PolicyScriptComponent("Prerequisite Script", "Script to install MicrosoftTeams module and login to MicrosoftTeams using Poweshell.", prerequisiteScript, "PrerequisiteScript.ps1")}
                {PolicyScriptComponent("Recording Policy Creation Script", "Script to create new recording policy", recordingPolicyCreationScript.replace("@@RecordingBotAppId@@", platformStatus?.msTeamsPlatformStatus?.botAppId ?? ""), "RecordingPolicyCreationScript.ps1")}
                {PolicyScriptComponent("Recording Policy Validation Script", "Recording policy should have application instance's object id in ComplianceRecordingApplications.", recordingPolicyValidationScript, "RecordingPolicyValidationScript.ps1")}
                <div className="float-left w-100 p-t-20 p-l-10 p-r-5">
                  <Checkbox
                    value={platformStatus?.msTeamsPlatformStatus?.isRecordingPolicyCreated ?? false}
                    onChange={handlePolicyCreatedMark}
                  />
                  <span className="m-l-10">Please mark if you have created recording policy successfully.</span>
                </div>
              </div>
            }
            {stepNumber === 3 && 
              <div className="">
                <span className="text-primary font-weight-semi fs-14 p-b-10 float-left w-100 d-flex align-items-center">
                  <span>Assign Recording Policy</span>
                </span>
                {PolicyScriptComponent("Prerequisite Script", "Script to install MicrosoftTeams module and login to MicrosoftTeams using Poweshell.", prerequisiteScript, "PrerequisiteScript.ps1")}
                <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                  <div className="visibleDataDiv float-left w-100">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="topCodeBox float-left w-100">
                          <div className="dropBox float-left">
                            <div className="dropBox">
                              <div className="dropBoxH p-r-5">
                                <i className="bi bi-record-fill"></i>
                                <span className="fw-bold p-l-5 p-r-10">Select users to assign recording policy:</span>
                              </div>
                              {!assignToAllUsers && 
                                <div className="dropBoxInput" style={{maxWidth: "500px"}}>
                                  <CustomMultiSelect
                                    data={masterData?.users?.filter((user: TinyUser) => !user.isArchived)}
                                    onChange={(event: MultiSelectChangeEvent) =>
                                      setAssignedUsers([...event.value])
                                    }
                                    value={assignedUsers}
                                    dataItemKey="id"
                                    itemRender={itemRenderUser}
                                    textField="email"
                                    filtering={true}
                                    placeholder={
                                      translationsLoading
                                        ? "Please select..."
                                        : fetchLabelKeyTranslation(
                                            "PlaceholderMultiSelectOne",
                                            "Please select..."
                                          )
                                    }
                                  />
                                </div>
                              }
                              
                            </div>
                          </div>
                          <div className="codeBoxActionBtn float-right">
                            <Button
                              className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                              style={{ height: "26px" }}
                              disabled={!assignToAllUsers && assignedUsers.length === 0}
                            >
                              <span
                                onClick={() => {
                                  if(assignToAllUsers){
                                    downloadScript(
                                      "AssigningRecordingPolicyToAllUsersScript.ps1",
                                      assigningRecordingPolicyToAllUsersScript
                                    )
                                  }else{
                                    downloadScript(
                                      "AssigningRecordingPolicyToUsersScript.ps1",
                                      getUpdatedAssigningRecordingPolicyToUsersScript()
                                    )
                                  }
                                }
                                }
                                className="p-r-2"
                              >
                                {translationsLoading
                                  ? "Download"
                                  : fetchLabelKeyTranslation(
                                      "BtnDownload",
                                      "Download"
                                    )}
                              </span>
                              <i className="bi bi-download"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="float-left w-100 p-t-5 p-l-20 p-r-5">
                        <Checkbox
                          value={assignToAllUsers}
                          onChange={(e) => setAssignToAllUsers(e.value)}
                        />
                        <span className="m-l-10 fs-14">Assign recording policy to all users in the organization.</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="codeData">
                          <Button
                            onClick={() => {
                              if(assignToAllUsers){
                                navigator.clipboard.writeText(assigningRecordingPolicyToAllUsersScript);
                              }else{
                                navigator.clipboard.writeText(
                                  getUpdatedAssigningRecordingPolicyToUsersScript()
                                );
                              }
                            }}
                            className="copCodeDataBtn"
                          >
                            <span>
                              {" "}
                              {translationsLoading
                                ? "Copy Code"
                                : fetchLabelKeyTranslation(
                                    "BtnCopyCode",
                                    "Copy Code"
                                  )}
                            </span>
                            <i className="bi bi-files p-l-3"></i>
                          </Button>
                          <div
                            className="fs-13"
                            style={{
                              maxHeight: "200px",
                              overflow: "auto",
                              margin: "0 0 10px 0",
                            }}
                          >
                            <p style={{ whiteSpace: "pre-line" }}>
                              {assignToAllUsers ? assigningRecordingPolicyToAllUsersScript : assigningRecordingPolicyToUsersScript.replace(`"<username@domain.com>"`, assignedUsers.map(u => `"` + u.email + `"`).join(', '))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {PolicyScriptComponent("Validate Assigned Users Script", "Script to get the list of all assigned users.", validateAssignedUsersScript, "ValidateAssignedUsersScript.ps1")}
              </div>
            }
            { stepNumber < stepperItemList.length-1 &&
              <div className="row m-t-20">
                <div className="col-md-12">
                  <div className="topCodeBox float-left w-100">
                    <div className="dropBox float-left">
                      <div className="dropBox"></div>
                    </div>
                    <div className="codeBoxActionBtn float-right">
                      <Button
                        className="btn bg-primary text-white m-r-5 p-r-7 p-l-7"
                        style={{ height: "26px" }}
                        onClick={() => setStepNumber(stepNumber+1)}
                      >
                        <i className="bi bi-arrow-right"></i>
                        <span className="p-l-5">Next Step</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsTeams;
