import { useContext, useEffect, useState } from "react";
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import useBranding from "./useBranding";

interface SwalHookReturnType{
  fire : (options : SweetAlertOptions<any, any>) => Promise<SweetAlertResult<any>>
}

const useSwal = () : SwalHookReturnType => {
  const brandingCtx = useBranding()
  const fire = async (options : SweetAlertOptions<any, any>) : Promise<SweetAlertResult<any>> => {
    const optionsWithBranding : SweetAlertOptions<any, any> = {
      ...options,
      iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
      confirmButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
      cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue
    }
    var res = await Swal.fire({...optionsWithBranding})
    return res;
}

  return {fire}
};

export default useSwal;