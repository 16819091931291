import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { SortDescriptor, orderBy, process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridCellProps,
  GridNoRecords,
  GridSortChangeEvent,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import Swal from "sweetalert2";
import useAuth from "../../../../hooks/useAuth";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import CustomSearchFieldTextInput from "../../../../components/custom/form/CustomSearchFieldTextInput";
import { Tooltip } from "@progress/kendo-react-tooltip";
import recordingPolicySettingsService from "../../../../services/recordingPolicySettings.service";
import UpsertRecordingPolicyDialog from "./UpsertRecordingPolicyDialog";
import { AxiosError } from "axios";
import useMasterData from "../../../../hooks/useMasterData";
import RecordingPolicySettingsForMobile from "./RecordingPolicySettingsForMobile";

interface ProductNameHeaderProps extends GridHeaderCellProps {
  children: any;
}

interface CustomGridCellProps extends GridCellProps {
  dataItem: ParticipantNumber;
}

export interface ParticipantNumber {
  id: number;
  participantNumber: string;
}

export interface UpdateParticipantNumber {
  excludedNumber: string;
}

export interface Page {
  skip: number;
  take: number;
}

interface RecordingPolicySettingsProps {
  setPageFreezeLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecordingPolicySettings: React.FC<RecordingPolicySettingsProps> = ({
  setPageFreezeLoader,
}) => {
  const masterData = useMasterData();
  const auth = useAuth();
  const localeCtx = useLocale();

  const initialDataState: Page = {
    skip: 0,
    take: 10,
  };

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
    "RecordingPolicySettings"
    ]
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [participantNumbers, setParticipantNumbers] = useState<
    ParticipantNumber[]
  >([]);
  const [participantNumberEdit, setParticipantNumberEdit] = useState<
    ParticipantNumber | undefined
  >(undefined);
  const [pageData, setPageData] = useState<Page>(initialDataState);
  const [sort, setSort] = useState([
    { dir: "asc", field: "participantNumber" },
  ] as SortDescriptor[]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [error, setError] = useState<string | undefined>();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "RecordingPolicySettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    sendRecordingPolicyRequest();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "RecordingPolicySettings"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
        "RecordingPolicySettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("RecordingPolicySettings");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const sendRecordingPolicyRequest = async () => {
    setParticipantNumbers([]);
    setLoading(true);
    try {
      const data: ParticipantNumber[] =
        await recordingPolicySettingsService.getRecordingPolicyData(
          auth?.tokenPayload?.CustomerId
        );
      const sortedData: ParticipantNumber[] = data.sort(
        (a: ParticipantNumber, b: ParticipantNumber) => {
          if (a.participantNumber > b.participantNumber) return 1;
          if (a.participantNumber < b.participantNumber) return -1;
          return 0;
        }
      );
      setParticipantNumbers(sortedData);
    } catch (ex) {
      if (ex instanceof Error) {
        setError(ex.message);
        setParticipantNumbers([]);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: any) => {
    setPageData(event.page);
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    setPageData(initialDataState);
  };

  const CellParticipantNumber = (props: GridCellProps) => {
    return (
      <td className="uCardCol mx-th-tag">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                {props.dataItem.participantNumber}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };
  
  const onEditHandler = (data: ParticipantNumber) => {
    setParticipantNumberEdit(data);
    setVisible(true);
  };

  const onDeleteHandler = (data: ParticipantNumber) => {
    setPageFreezeLoader(false);
    Swal.fire({
      title: `${translationsLoading
          ? "Are you sure?"
          : fetchLabelKeyTranslation("WarningTitle", "Are you sure?")
        }`,
      text: `${translationsLoading
          ? "You won't be able to revert this!"
          : fetchLabelKeyTranslation(
            "WarningText",
            "You won't be able to revert this!"
          )
        }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${translationsLoading
          ? "Yes, delete it!"
          : fetchLabelKeyTranslation("ConfirmButtonText", "Yes, delete it!")
        }`,
      cancelButtonText: `${translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelButton", "Cancel")
        }`,
    }).then((result) => {
      if (result.isConfirmed) {
        setPageFreezeLoader(true);
        recordingPolicySettingsService
          .deleteParticipantNumber(data.id, auth?.tokenPayload?.CustomerId)
          .then(() => {
            setPageFreezeLoader(false);
            Swal.fire({
              icon: "success",
              title: `${translationsLoading
                  ? "This number will now be recorded"
                  : fetchLabelKeyTranslation(
                    "WarningTitleDeleted",
                    "This number will now be recorded"
                  )
                }`,
              confirmButtonText: `${translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
                }`,
            });
            const updatedParticipantNumbers = participantNumbers?.filter(
              (number: ParticipantNumber) => number.id !== data.id
            );
            setParticipantNumbers(updatedParticipantNumbers);
            const gridDataResult = process(
              updatedParticipantNumbers.filter((number: ParticipantNumber) =>
                searchRecordingPolicy(number)
              ),
              pageData
            );
            if (
              gridDataResult.data.length === 0 &&
              gridDataResult.total > 0
            ) {
              const currentPageNumber = Math.floor(
                gridDataResult.total / pageData.take
              );
              setPageData({
                skip:
                  gridDataResult.total % pageData.take === 0
                    ? (currentPageNumber - 1) * pageData.take
                    : currentPageNumber * pageData.take,
                take: pageData.take,
              });
            }
          })
          .catch((err) => {
            setPageFreezeLoader(false);
            if (err instanceof AxiosError) {
              let errorMessage: string = translationsLoading
                ? "Uh Oh! Something Went Wrong. Please Try Again!"
                : fetchLabelKeyTranslation(
                  "ErrMsg",
                  "Uh Oh! Something Went Wrong. Please Try Again!"
                );
              if (err.response?.status === 404) {
                errorMessage = translationsLoading
                  ? "Participant number not found."
                  : fetchLabelKeyTranslation(
                    "ErrorDeletePNNotFound",
                    "Participant number not found."
                  );
              }
              Swal.fire({
                icon: "error",
                title: `${translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation("ErrorTitle", "Error")
                  }`,
                text: errorMessage,
                confirmButtonText: `${translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                  }`,
              });
            }
          });
      }
    });
  };
  
  const CustomCellWithActions = (props: CustomGridCellProps) => {
    const data = props.dataItem;

    return (
      <td className="text-right">
        <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
          <span className="iBtn d-flex justify-content">
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn ${masterData?.telepoDisableConfig?.systemSetting.recordingPolicy
                  .disableChanges
                  ? "disabledBtn"
                  : ""
                }`}
              onClick={() => onEditHandler(data)}
              disabled={
                masterData?.telepoDisableConfig?.systemSetting.recordingPolicy
                  .disableChanges
              }
            >
              <span
                className="fs-17 line-height-1 text-primary"
                title={`${translationsLoading
                    ? "Edit"
                    : fetchLabelKeyTranslation("EditButton", "Edit")
                  }`}
              >
                <i className="bi bi-pencil"></i>
              </span>
            </Button>
            <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn ${masterData?.telepoDisableConfig?.systemSetting.recordingPolicy
                  .disableChanges
                  ? "disabledBtn"
                  : ""
                }`}
              onClick={() => onDeleteHandler(data)}
              disabled={
                masterData?.telepoDisableConfig?.systemSetting.recordingPolicy
                  .disableChanges
              }
            >
              <span
                className="fs-17 line-height-1 text-primary"
                title={`${translationsLoading
                    ? "Delete"
                    : fetchLabelKeyTranslation("DeleteButton", "Delete")
                  }`}
              >
                <i className="bi bi-trash3"></i>
              </span>
            </Button>
          </span>
        </Tooltip>
      </td>
    );
  };

  const NotClickableHeader = (props: ProductNameHeaderProps) => {
    return (
      <div>
        <span style={{ cursor: "default" }}>{props.title}</span>
      </div>
    );
  };

  const toggleDialog = () => {
    setVisible(!visible);
    setParticipantNumberEdit(undefined);
  };

  const upsertRecordingPolicyData = (
    updatedParticipantNumber?: ParticipantNumber,
    insertedParticipantNumber?: ParticipantNumber[]
  ) => {
    if (updatedParticipantNumber) {
      const updatedParticipantNumbers: ParticipantNumber[] =
        participantNumbers.map((number: ParticipantNumber) => {
          if (number.id === updatedParticipantNumber.id) {
            number.participantNumber =
              updatedParticipantNumber.participantNumber;
          }
          return number;
        });
      setParticipantNumbers(updatedParticipantNumbers);
    } else {
      setSearchTerm("");
      if (insertedParticipantNumber) {
        setParticipantNumbers([
          ...participantNumbers,
          insertedParticipantNumber[insertedParticipantNumber.length - 1],
        ]);
        const currentPageNumber = Math.floor(
          insertedParticipantNumber.length / pageData.take
        );
        setPageData({
          skip:
            insertedParticipantNumber.length % pageData.take === 0
              ? (currentPageNumber - 1) * pageData.take
              : currentPageNumber * pageData.take,
          take: pageData.take,
        });
      }
    }
  };

  const searchRecordingPolicy = (number: ParticipantNumber) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();
    if (number.participantNumber?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    return false;
  };

  const CustomCellForMobileView = (mobileViewProps: GridCellProps) => {
    return (
      <RecordingPolicySettingsForMobile
        {...mobileViewProps}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
        loading={loading}
        error={error}
      />
    );
  };

  return (
    <>
      <div className="callList float-left w-100 maxTableCol m-b-20">
        <div className="callListInner">
          <div className="callListBox">
            <div className="row">
              <div className="col-md-12">
                <div className="tableTitle d-flex align-items-center justify-content-between trk-t text-black-14 border-bottom-solid border-w-1 border-black-1 flex-wrap"
                  // style= {{ flexWrap: "wrap" }}
                >
                  <span className="text-primary">
                    {`${translationsLoading
                        ? "Excluded Numbers"
                        : fetchLabelKeyTranslation(
                          "RecordingPolicyTitle",
                          "Excluded Numbers"
                        )
                      }`}
                  </span>
                  <div className="buttons-container hov-transparent d-flex align-itmes-center">
                    <div className="searchBox searchCol">
                      <CustomSearchFieldTextInput
                        className="input-search"
                        placeholder={`${translationsLoading
                            ? "Search..."
                            : fetchLabelKeyTranslation(
                              "SearchPlaceholder",
                              "Search..."
                            )
                          }`}
                        value={searchTerm}
                        updateValue={setSearchTerm}
                        searchTextChangeHandler={handleSearchChange}
                        onEscapeKeyFunc={true}
                        disabled={loading || error !== undefined}
                      />
                    </div>

                    <Button
                      disabled={
                        loading ||
                        error !== undefined ||
                        masterData?.telepoDisableConfig?.systemSetting
                          .recordingPolicy.disableChanges
                      }
                      onClick={toggleDialog}
                      className={`btn bg-primary text-white fs-13 fw-normal m-l-10 ${loading ||
                          error !== undefined ||
                          masterData?.telepoDisableConfig?.systemSetting
                            .recordingPolicy.disableChanges
                          ? "disabledBtn"
                          : ""
                        }`}
                      style={{ height: "29px", margin: "-1px 0 0 0" }}
                      title={`${translationsLoading
                          ? "Add"
                          : fetchLabelKeyTranslation("AddButton", "Add")
                        }`}
                    >
                      {translationsLoading
                        ? "Add"
                        : fetchLabelKeyTranslation("AddButton", "Add")}
                    </Button>
                    {visible && (
                      <UpsertRecordingPolicyDialog
                        toggleDialog={toggleDialog}
                        participantNumberEdit={participantNumberEdit}
                        upsertRecordingPolicyData={upsertRecordingPolicyData}
                        participantNumbers={participantNumbers}
                        setPageFreezeLoader={setPageFreezeLoader}
                        disabledTelepoConfig={
                          masterData?.telepoDisableConfig?.systemSetting
                            .recordingPolicy.disableChanges ?? false
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="tableList rowSpace float-left w-100">
                  <div className="tableMain gridContentOverflowY-hidden table-mobile">
                    <Grid
                      data={orderBy(
                        participantNumbers
                          .filter((number: ParticipantNumber) =>
                            searchRecordingPolicy(number)
                          )
                          .slice(
                            pageData.skip,
                            pageData.take + pageData.skip
                          ) ?? [],
                        sort
                      )}
                      skip={pageData.skip}
                      take={pageData.take}
                      total={
                        participantNumbers?.filter((t: ParticipantNumber) =>
                          searchRecordingPolicy(t)
                        ).length
                      }
                      pageable={{ buttonCount: 4, pageSizes: true }}
                      onPageChange={handlePageChange}
                      sort={sort}
                      sortable={true}
                      onSortChange={(e: GridSortChangeEvent) => {
                        let tempSort = e.sort;
                        if (e.sort.length === 0 && sort.length !== 0) {
                          tempSort = sort.map((s) => {
                            return { field: s.field, dir: "asc" };
                          });
                        }
                        setParticipantNumbers(
                          orderBy(
                            participantNumbers?.filter(
                              (el: ParticipantNumber) =>
                                searchRecordingPolicy(el)
                            ) ?? [],
                            tempSort
                          )
                        );
                        setSort(tempSort);
                      }}
                    >
                      <GridNoRecords>
                        {loading && <Loader type={"infinite-spinner"} />}
                        {!loading && error && (
                          <span className="fs-15">
                            <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                            {`${translationsLoading
                                ? "Uh Oh! Something Went Wrong. Please Try Again!"
                                : fetchLabelKeyTranslation(
                                  "ErrMsg",
                                  "Uh Oh! Something Went Wrong. Please Try Again!"
                                )
                              }`}
                          </span>
                        )}
                        {!loading &&
                          !error &&
                          `${translationsLoading
                            ? "No Participants Excluded"
                            : fetchLabelKeyTranslation(
                              "NoParticipantsExcludedText",
                              "No Participants Excluded"
                            )
                          }`}
                      </GridNoRecords>
                      <GridColumn
                          width={0}
                          field="cellMobile"
                          cell={CustomCellForMobileView}
                        />
                      <GridColumn
                        field="participantNumber"
                        title={`${translationsLoading
                            ? "Numbers"
                            : fetchLabelKeyTranslation(
                              "NumbersColumnText",
                              "Numbers"
                            )
                          }`}
                        cell={CellParticipantNumber}
                      />
                      <GridColumn
                        field="topicActions"
                        title={`${translationsLoading
                            ? "Actions"
                            : fetchLabelKeyTranslation(
                              "ActionsTitle",
                              "Actions"
                            )
                          }`}
                        cell={CustomCellWithActions}
                        sortable={false}
                        headerCell={NotClickableHeader}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordingPolicySettings;
