import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import { CallDetailTabEnum } from "../../../enums/callDetailTabEnum";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
  handleCallDetailNavigation: (
    callDetail: CallDetail,
    tab: CallDetailTabEnum
  ) => void;
}

const CustomGridCellForCallComments = (props: CustomGridCellProps) => {
  const totalComments = props.dataItem.recordings[0].totalComments;
  return (
    <td className="text-left">
      <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
        <span
          className="line-height-1 text-primary cursor-pointer"
          title={"Comments: " + (totalComments ? totalComments : 0)}
          onClick={() =>
            props.handleCallDetailNavigation(
              props.dataItem,
              CallDetailTabEnum.Comments
            )
          }
        >
          <i
            className="bi bi-chat-left fs-13"
            style={{ padding: "5px 4px 0 0", float: "left" }}
          ></i>
          <span className="fs-12 p-l-2">
            {totalComments ? totalComments : 0}
          </span>
        </span>
      </Tooltip>
    </td>
  );
};

export default CustomGridCellForCallComments;
