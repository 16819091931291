import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridCellProps,
  GridNoRecords,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import Swal from "sweetalert2";
import { AccessPermissionEnum } from "../../../../enums/accessPermissionEnum";
import useAuth from "../../../../hooks/useAuth";
import auditLogsService from "../../../../services/auditLogs.service";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomSearchFieldTextInput from "../../../../components/custom/form/CustomSearchFieldTextInput";
import UnauthorizedAccess from "../../../error/UnauthorizedAccess";
import { AuditLogsData, Page } from "../../../../types/audit-logs/AuditLogs";
import { TinyUser } from "../../../../types/user";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import AuditFilterDrawer from "../../../../components/AuditFilterDrawer";
import { AuditFilter } from "../../../../types/filters";
import moment from "moment";
import 'moment/min/locales';
import { formatDateTime } from "../../../../utils/dateTimeUtils";
import { PeriodFilterTypes } from "../../../../data/PeriodFilterTypes";
import useMasterData from "../../../../hooks/useMasterData";
import { CallDetail } from "../../../../types/call";
import { AuditEntityType } from "../../../../types/master-data/AuditEntityType";
import AuditLogSettingsForMobile from "./AuditLogSettingsForMobile";
import { ExcludedColumnsEnum } from "../../../../enums/excludedColumnsEnum";

interface AuditLogSettingsData {
  filters: boolean;
  search: boolean;
  title: boolean;
  callDetail?: CallDetail;
  excludedColumns: string[];
}

const AuditLogSettings: React.FC<AuditLogSettingsData> = ({
  filters,
  search,
  title,
  callDetail,
  excludedColumns,
}) => {
  const auth = useAuth();
  const master = useMasterData();

  const initialDataState: Page = {
    skip: 0,
    take: 10,
    sort: { sortField: "ChangedAt", sortDirection: "desc" },
    totalRecords: 0,
  };

  const localeCtx = useLocale();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
    "AuditLogsSettings"
    ]
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [auditLogs, setAuditLogs] = useState<AuditLogsData | undefined>(
    undefined
  );
  const [pageData, setPageData] = useState<Page>(initialDataState);
  const [sort, setSort] = React.useState([] as SortDescriptor[]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [error, setError] = useState<string | undefined>();

  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [auditFilter, setAuditFilter] = useState<AuditFilter>();

  useEffect(() => {
    const filterValues: AuditFilter = {
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      periodType: 1,
      filterType: 1,
      entityType: [],
      entityFriendlyName: "",
      changedBy: [],
    };
    setAuditFilter(filterValues);
  }, []);

  useEffect(() => {
    if (auditFilter && master?.data) sendAuditLogsRequest(auditFilter);
  }, [
    pageData.take,
    pageData.skip,
    pageData.sort.sortField,
    pageData.sort.sortDirection,
    auditFilter,
    master?.data,
  ]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "AuditLogsSettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const sendAuditLogsRequest = async (filter: AuditFilter) => {
    setAuditLogs(undefined);
    setLoading(true);
    try {
      const reducedEntityTypes: string[] = [];
      master?.data?.auditEntityTypes
        ?.filter((eType: AuditEntityType) =>
          !callDetail
            ? eType.name !== "Call" &&
            eType.name !== "Recording" &&
            eType.name !== "RecordingComment" &&
            eType.name !== "RecordingScorecard"
            : eType.name === "Call" ||
            eType.name === "Recording" ||
            eType.name === "RecordingComment" ||
            eType.name === "RecordingScorecard"
        )
        .forEach((eType: AuditEntityType) =>
          reducedEntityTypes.push(eType.name)
        );
      const data: AuditLogsData = await auditLogsService.fetchAllAuditLogs(
        filter,
        pageData,
        searchTerm,
        reducedEntityTypes,
        callDetail?.platformCallDetailId
      );
      const sortObj: SortDescriptor[] = [
        { field: data.page.sort.sortField, dir: data.page.sort.sortDirection },
      ];
      setAuditLogs(data);
      setSort(sortObj);
      setPageData(data.page);
    } catch (ex) {
      const errorAuditData: AuditLogsData = {
        data: [],
        page: initialDataState,
      };
      if (ex instanceof Error) {
        setError(ex.message);
        setAuditLogs(errorAuditData);
        setPageData(initialDataState);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "AuditLogsSettings"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
        "AuditLogsSettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("AuditLogsSettings");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const handlePageChange = (event: any) => {
    setPageData({ ...pageData, skip: event.page.skip, take: event.page.take });
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleFilterDialog = () => {
    setFilterVisible(!filterVisible);
  };

  const handleAuditDataFilter = (filterValues: AuditFilter) => {
    setPageData({ ...pageData, skip: 0, take: pageData.take });
    setFilterVisible(!filterVisible);
    setAuditFilter(filterValues);
  };

  const CellChangedAt = (props: GridCellProps) => {
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1">
                {props.dataItem.changedAt}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellChangedBy = (props: GridCellProps) => {
    const userInfo: TinyUser = {
      id: props.dataItem.changedBy.id,
      loginUserId: props.dataItem.changedBy.loginUserId,
      firstName: props.dataItem.changedBy.firstName,
      lastName: props.dataItem.changedBy.lastName,
      email: props.dataItem.changedBy.email,
      phoneNo: props.dataItem.changedBy.phoneNo,
      image: props.dataItem.changedBy.image,
      isArchived: props.dataItem.changedBy.isArchived,
    };
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className=" cursor-default text-decoration-none float-left w-100 line-height-1">
                <CustomUserGridInfo userInfo={userInfo} />
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellEntityType = (props: GridCellProps) => {
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className=" cursor-default text-decoration-none float-left w-100 line-height-1">
                {master?.data?.auditEntityTypes.find((p) => p.name === props.dataItem.entityType.name)?.localizationValue}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellEntityName = (props: GridCellProps) => {
    return (
      <td className="uCardCol mx-th-tag">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                {props.dataItem.entityFriendlyName}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellEntityActionType = (props: GridCellProps) => {
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className=" cursor-default text-decoration-none float-left w-100 line-height-1">
                {master?.data?.auditEntityActionType.find((p) => p.name === props.dataItem.entityActionType.name)?.localizationValue}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellFieldName = (props: GridCellProps) => {
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className=" cursor-default text-decoration-none float-left w-100 line-height-1">
                {props.dataItem.fieldName}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellOldFieldValue = (props: GridCellProps) => {
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className=" cursor-default text-decoration-none float-left w-100 line-height-1">
                {props.dataItem.oldFieldValue}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CellNewFieldValue = (props: GridCellProps) => {
    return (
      <td className="uCardCol">
        <div className="tblUsr">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className=" cursor-default text-decoration-none float-left w-100 line-height-1">
                {props.dataItem.newFieldValue}
              </span>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const CustomCellForMobileView = (mobileViewProps: GridCellProps) => {
    return (
      <AuditLogSettingsForMobile
        {...mobileViewProps}
        excludedColumns = {excludedColumns}
        loading = {loading}
        error = {error}
      />
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageAccount) ? (
        <UnauthorizedAccess />
      ) : (
        <div className="callList float-left w-100 maxTableCol m-b-20">
          <div className="callListInner">
            <div className="callListBox">
              {(title || filters || search) && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="tableTitle d-flex align-items-center justify-content-between">
                      {title && (
                        <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                          <span className="text-primary">
                            {`${translationsLoading
                              ? "Audit Logs"
                              : fetchLabelKeyTranslation(
                                "AuditLogsTitle",
                                "Audit Logs"
                              )
                              }`}
                          </span>
                        </div>
                      )}
                      <div className="buttons-container hov-transparent d-flex align-itmes-center">
                        {search && (
                          <div className="searchBox searchCol">
                            <CustomSearchFieldTextInput
                              className="input-search"
                              placeholder={`${translationsLoading
                                ? "Search..."
                                : fetchLabelKeyTranslation(
                                  "SearchPlaceholder",
                                  "Search..."
                                )
                                }`}
                              value={searchTerm}
                              updateValue={setSearchTerm}
                              searchTextChangeHandler={handleSearchChange}
                              onEscapeKeyFunc={true}
                              handleOnKeyDown={(event: KeyboardEvent) => {
                                if (event.key === "Enter" && auditFilter) {
                                  if (pageData.skip === 0) {
                                    sendAuditLogsRequest(auditFilter);
                                  } else {
                                    setPageData({
                                      ...pageData,
                                      skip: 0,
                                      take: pageData.take,
                                    });
                                  }
                                }
                              }}
                            />
                          </div>
                        )}

                        {!filterVisible && filters && (
                          <Tooltip
                            anchorElement={"target"}
                            parentTitle={true}
                            position={"top"}
                            style={{ zIndex: 999 }}
                          >
                            <Button
                              className="btn btn-link iconBtn p-0 m-l-10"
                              onClick={handleFilterDialog}
                            >
                              <i
                                className="bi bi-sliders fs-20 line-height-1 text-primary"
                                title="Filter"
                              ></i>
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="tableList rowSpace float-left w-100">
                    <div className="tableMain gridContentOverflowY-hidden table-mobile">
                      <Grid
                        data={orderBy(auditLogs?.data ?? [], sort)}
                        skip={pageData.skip}
                        take={pageData.take}
                        total={auditLogs?.page.totalRecords}
                        pageable={{ buttonCount: 4, pageSizes: true }}
                        onPageChange={handlePageChange}
                        sort={sort}
                        sortable={true}
                        onSortChange={(e: GridSortChangeEvent) => {
                          let tempSort = e.sort;
                          if (e.sort.length === 0 && sort.length !== 0) {
                            tempSort = sort.map((s) => {
                              return { field: s.field, dir: "asc" };
                            });
                          }
                          setPageData({
                            ...pageData,
                            sort: {
                              sortField: !e.sort.length
                                ? tempSort[0].field
                                : e.sort[0].field,
                              sortDirection: !e.sort.length
                                ? tempSort[0].dir
                                : e.sort[0].dir,
                            },
                          });
                          setSort(tempSort);
                        }}
                      >
                        <GridNoRecords>
                          {loading && <Loader type={"infinite-spinner"} />}
                          {!loading && error && (
                            <span className="fs-15">
                              <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                              {`${translationsLoading
                                ? "Uh Oh! Something Went Wrong. Please Try Again!"
                                : fetchLabelKeyTranslation(
                                  "ErrMsg",
                                  "Uh Oh! Something Went Wrong. Please Try Again!"
                                )
                                }`}
                            </span>
                          )}
                          {!loading &&
                            !error &&
                            `${translationsLoading
                              ? "No Records Available."
                              : fetchLabelKeyTranslation(
                                "NoRecordText",
                                "No Records Available."
                              )
                            }`}
                        </GridNoRecords>
                        <GridColumn
                          width={0}
                          field="cellMobile"
                          cell={CustomCellForMobileView}
                        />
                        {!excludedColumns.includes(ExcludedColumnsEnum.EntityType) && (
                          <GridColumn
                            field="EntityType"
                            title={`${translationsLoading
                              ? "Entity Type"
                              : fetchLabelKeyTranslation(
                                "EntityTypeColumnText",
                                "Entity Type"
                              )
                              }`}
                            cell={CellEntityType}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.entityFriendlyName) && (
                          <GridColumn
                            field="entityFriendlyName"
                            title={`${translationsLoading
                              ? "Entity Name"
                              : fetchLabelKeyTranslation(
                                "EntityNameColumnText",
                                "Entity Name"
                              )
                              }`}
                            cell={CellEntityName}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.Action) && (
                          <GridColumn
                            field="Action"
                            title={`${translationsLoading
                              ? "Action"
                              : fetchLabelKeyTranslation(
                                "ActionColumnText",
                                "Action"
                              )
                              }`}
                            cell={CellEntityActionType}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.Property) && (
                          <GridColumn
                            field="Property"
                            title={`${translationsLoading
                              ? "Property"
                              : fetchLabelKeyTranslation(
                                "PropertyColumnText",
                                "Property"
                              )
                              }`}
                            cell={CellFieldName}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.OldValue) && (
                          <GridColumn
                            field="OldValue"
                            title={`${translationsLoading
                              ? "Old Value"
                              : fetchLabelKeyTranslation(
                                "OldValueColumnText",
                                "Old Value"
                              )
                              }`}
                            cell={CellOldFieldValue}
                            sortable={false}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.NewValue) && (
                          <GridColumn
                            field="NewValue"
                            title={`${translationsLoading
                              ? "New Value"
                              : fetchLabelKeyTranslation(
                                "NewValueColumnText",
                                "New Value"
                              )
                              }`}
                            cell={CellNewFieldValue}
                            sortable={false}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.ChangedBy) && (
                          <GridColumn
                            field="ChangedBy"
                            title={`${translationsLoading
                              ? "Changed By"
                              : fetchLabelKeyTranslation(
                                "ChangedByColumnText",
                                "Changed By"
                              )
                              }`}
                            cell={CellChangedBy}
                            sortable={loading ? false : true}
                          />
                        )}

                        {!excludedColumns.includes(ExcludedColumnsEnum.ChangedAt) && (
                          <GridColumn
                            field="ChangedAt"
                            title={`${translationsLoading
                              ? "Changed At"
                              : fetchLabelKeyTranslation(
                                "ChangedAtColumnText",
                                "Changed At"
                              )
                              }`}
                            cell={CellChangedAt}
                            sortable={loading ? false : true}
                          />
                        )}
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {filterVisible && (
              <div className="callListBoxFilter">
                <div className="listBoxCard">
                  <div className="float-left w-100">
                    <div
                      className="float-left w-100 d-flex fs-14 align-items-center justify-content-between font-weight-semi p-t-3 p-r-10 p-b-3 p-l-15"
                      style={{ minHeight: "36px" }}
                    >
                      <div className="float-left">
                        <span className="p-r-5">Date:</span>
                        <span className="panelTitleBadge fs-12">
                          {auditFilter?.periodType === 4
                            ? auditFilter.startDate && auditFilter.endDate
                              ? formatDateTime(
                                auditFilter.startDate,
                                "MMM DD, YYYY"
                              ) +
                              " - " +
                              formatDateTime(
                                auditFilter.endDate,
                                "MMM DD, YYYY"
                              )
                              : "Custom"
                            : PeriodFilterTypes.find(
                              (e) => e.id === auditFilter?.periodType
                            )?.filters?.find(
                              (el) => el.id === auditFilter?.filterType
                            )?.title}
                        </span>
                      </div>
                      <span className="float-right">
                        <Tooltip
                          anchorElement={"target"}
                          parentTitle={true}
                          position={"top"}
                          style={{ zIndex: 999 }}
                        >
                          <Button
                            className="buttons-container-button radius-50 p-0"
                            style={{ width: "30px", height: "30px" }}
                            title="Close"
                            onClick={handleFilterDialog}
                          >
                            <i className="bi bi-x-lg fs-20 line-height-1 text-primary"></i>
                          </Button>
                        </Tooltip>
                      </span>
                    </div>
                    <AuditFilterDrawer
                      auditFilterData={auditFilter}
                      handleAuditDataFilter={handleAuditDataFilter}
                      handleFilterDialog={handleFilterDialog}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AuditLogSettings;
