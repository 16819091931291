import axios, { AxiosError,AxiosRequestConfig } from "axios";
import { Location, NavigateFunction, redirect } from "react-router-dom";
import { API_BASE_URL,IS_ENCRYPTION_ENABLED } from "../constants";
import { parseJSON } from "./customJsonParser";
import { AppLocale } from "../contexts/LocaleProvider";
import {appUrl} from "../index";
import { encryption, encryptionResponse } from "./encryptionUtil";

export const axiosWithAuth = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosWithoutAuth = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosForShare = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosWithoutAuth.interceptors.request.use(async (config) => {
  const locale=parseJSON<AppLocale>(sessionStorage.getItem("AppLocale"))?.current.locale.code;
    config.headers = config.headers ?? {};
    config.headers.AppURL=appUrl??"";
    config.headers.Locale=locale??"";
  return config;
});

axiosForShare.interceptors.request.use(async (config) => {
  const shareToken = parseJSON(sessionStorage.getItem("ShareToken"));
  const locale=parseJSON<AppLocale>(sessionStorage.getItem("AppLocale"))?.current.locale.code;
  if (shareToken !== null) {
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${shareToken}`;
    config.headers.AppURL=appUrl??"";
    config.headers.Locale=locale??"";
  }
  return config;
});

axiosWithAuth.interceptors.request.use(async (config) => {
  const token = parseJSON(localStorage.getItem("VoiceAIToken"));
  const locale=parseJSON<AppLocale>(sessionStorage.getItem("AppLocale"))?.current.locale.code;
  if (token !== null) {
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.AppURL=appUrl??"";
    config.headers.Locale=locale??"";
  }
  if (IS_ENCRYPTION_ENABLED) {
    config = await confirmEncryption(config);
  }
  return config;
});

const confirmEncryption = async (config: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> => {
  if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
    if (config.headers?.isEncrypted) {
      const originalPayload = { ...config.data };
      const modifiedPayload: encryptionResponse | string = await encryption(JSON.stringify(originalPayload));
      config.data = modifiedPayload;
    }
  }
  return config;
}

export const setUpAuthAxiosInterceptors = (
  location: Location,
  navigate: NavigateFunction,
  setUserAuthToken?: (token?: string) => void
) => {
  axiosWithAuth.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401 || error.response?.status===440) {
          console.log("401 error");
          if (setUserAuthToken) setUserAuthToken(undefined);
          if (location.pathname === "/login") {
            navigate(
              "/login",
              location.state &&
                location.state.from && { state: { from: location.state.from } }
            );
          } else {
            navigate("/login", { state: { from: location } });
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
