import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AccessPermissionEnum } from "../../../enums";
import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import useAuth from "../../../hooks/useAuth";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
  handlePlayerExpand: (callDetail: CallDetail) => void;
}

const CustomGridCellForCallPlayIcon = (props: CustomGridCellProps) => {
  const auth = useAuth();

  return (
    <td className="text-center">
      <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
        <span className="iBtn">
          {props.dataItem.expanded ? (
            <span title="Close">
              <Button
                style={{ opacity: "0.7" }}
                className="text-white radius-50 bg-primary"
                onClick={() => props.handlePlayerExpand(props.dataItem)}
                //disabled={!auth?.checkUserAccess(AccessPermissionEnum.ListenCalls)}
              >
                <i className="bi bi-x-lg"></i>
              </Button>
            </span>
          ) : (
            <span title="Play">
              <Button
                style={{ opacity: "0.7" }}
                className="text-white radius-50 bg-primary"
                onClick={() => props.handlePlayerExpand(props.dataItem)}
                //disabled={!auth?.checkUserAccess(AccessPermissionEnum.ListenCalls)}
              >
                <i className="bi bi bi-play fs-18"></i>
              </Button>
            </span>
          )}
        </span>
      </Tooltip>
    </td>
  );
};

export default CustomGridCellForCallPlayIcon;
